import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import Guideline from "./components/Guideline";
import VerifyMyKad from "./components/VerifyMyKad";
// import VerifyPassport from "../passport/components/VerifyPassport";
import VerifyFaceLiveness from "./components/VerifyFaceLiveness";
import VerifyDocumentLiveness from "./components/VerifyDocumentLiveness";
import VerifyDocumentVideo from "./components/VerifyDocumentVideo";
import VerifyHologram from "./components/VerifyHologram";
import Success from "./components/Success";
import EnterData from "./components/EnterData";
import { useEKYCService } from "./services/useEKYCService";
import ExpiredOrInvalidLink from "./components/ExpiredOrInvalidLink";

export const EKYCContext = React.createContext();

const EKYC3 = () => {
  const [step, setStep] = useState("");
  const [id, setId] = useState(null);
  const [config, setConfig] = useState(null);
  const [myKadOtherConfig, setMyKadOtherConfig] = useState(null);
  const [docType, setDocType] = useState(null);
  const [verifyMyKadResult, setVerifyMyKadResult] = useState(null); // This is the result of the MyKad verification. In future, don't use this. Use docVerifyResult instead.
  const [docVerifyResult, setDocVerifyResult] = useState(null);
  const [faceLivenessResult, setFaceLivenessResult] = useState(null);
  const [documentLivenessResult, setDocumentLivenessResult] = useState(null);
  const [detectHologramResult, setDetectHologramResult] = useState(null); // Added new state
  const [errorCode, setErrorCode] = useState(null);
  const [redirectURL, setRedirectURL] = useState(null);

  const location = useLocation();
  const { getValidationRecordFromToken } = useEKYCService();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    if (token) {
      getValidationRecordFromToken(token)
        .then((data) => {
          if (data.status === "success") {
            const redirectUrl = data.data.RedirectURL;

            if (data.data.StepTwoResult === "Pass" && redirectUrl) {
              window.location.href = redirectUrl;
              return;
            }

            setRedirectURL(redirectUrl);
            setId(data.data._default + "." + data.data._id);
            setDocType(data.data.docType);
            setConfig(data.data.config);
            setMyKadOtherConfig(data.data.MyKadOtherConfig);

            if (data.data.StepOneResult === undefined || data.data.StepOneResult === "Fail") {
              setStep(1);
            } else if (
              data.data.StepOneResult === "Pass" &&
              (data.data.StepTwoResult === "Fail" || data.data.StepTwoResult === undefined)
            ) {
              setDocVerifyResult(data.data.ColoredProfileBase64);
              setVerifyMyKadResult(data.data);
              setStep(4);
            }
          } else {
            setErrorCode(data.errorCode);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("An error occurred while processing the link");
        });
    } else {
      setStep(0);
    }
  }, [location, getValidationRecordFromToken]);

  // useEffect(() => {
  //   if (redirectURL) {
  //     console.log("redirectURL updated:", redirectURL);
  //   }
  // }, [redirectURL]);

  if (errorCode) {
    return <ExpiredOrInvalidLink errorCode={errorCode} />; // Render the ExpiredOrInvalidLink component if expired
  }

  const onStartAgain = () => {
    setStep(0);
    setVerifyMyKadResult(null);
    setFaceLivenessResult(null);
    setDocumentLivenessResult(null);
    setDetectHologramResult(null); // Added reset for new state
    if (redirectURL) {
      window.open(redirectURL, "_self");
    }
  };

  return (
    <EKYCContext.Provider
      value={{
        id,
        setId,
        config,
        setConfig,
        myKadOtherConfig,
        setMyKadOtherConfig,
        docType,
        setDocType,
        verifyMyKadResult, // This is the result of the MyKad verification. In future, don't use this. Use docVerifyResult instead.
        setVerifyMyKadResult, // This is the setter for the MyKad verification result. In future, don't use this. Use setDocVerifyResult instead.
        docVerifyResult,
        setDocVerifyResult,
        faceLivenessResult,
        setFaceLivenessResult,
        documentLivenessResult,
        setDocumentLivenessResult,
        detectHologramResult,
        setDetectHologramResult,
      }}
    >
      {step === 0 && <EnterData onNext={() => setStep(1)} />}

      {step === 1 && <Guideline onNext={() => setStep(2)} />}

      {step === 2 && (
        <VerifyMyKad
          onNext={() =>
            setStep(config?.["Take Photo of User With MyKad"] ? 3 : config?.["Take Video of MyKad"] ? 3.5 : 4)
          }
        />
      )}

      {step === 3 && config?.["Selfie With MyKad"] && (
        <VerifyDocumentLiveness onNext={() => setStep(config?.["Hologram Detection"] ? 3.5 : 4)} />
      )}

      {step === 3.5 && config?.["Hologram Detection"] && <VerifyHologram onNext={() => setStep(4)} />}

      {step === 4 && <VerifyFaceLiveness onNext={() => setStep(5)} />}

      {step === 5 && <Success onStartAgain={onStartAgain} />}
    </EKYCContext.Provider>
  );
};

export default EKYC3;
