import React from "react";
import Footer from "./Footer";
import { CircleCheck, CheckCircle, Camera } from "lucide-react";
import { Hourglass } from "react-loader-spinner";

const CheckQuality = (props) => {
  const { isSubmitting, myKadSide, imageBlob, onRetake, onConfirm } = props;

  const inputRef = React.useRef(null);

  const onChange = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);

    reader.onload = async () => {
      onRetake(reader.result);
    };
  };

  return (
    <div className="p-4 rounded-t-xl bg-white flex flex-col h-full min-h-screen">
      {/* Header section */}

      <h2 className="text-xl font-bold text-center mb-2">Check your photo quality</h2>

      {/* Content area with Apple-style spacing */}
      <div>
        {/* Image container with soft shadow and rounded corners */}
        <div className="flex flex-col items-center mt-4 mb-4">
          {imageBlob ? (
            <img
              src={URL.createObjectURL(imageBlob)}
              className="h-60 md:h-64 lg:h-80 w-auto rounded-lg shadow-md shadow-black"
            />
          ) : null}
          <p className="text-gray-500 font-bold text-center mt-2 flex items-center justify-center">Passport</p>
        </div>

        {/* <div className="h-8"></div> */}

        {/* Requirements section with iOS-style icons */}
        <div className="bg-gray-50 rounded-xl p-4 mb-6">
          <p className="text-sm text-gray-500 mb-3 font-medium">PLEASE ENSURE:</p>
          <div className="space-y-4 mt-4">
            <ul className="space-y-2">
              <li className="flex items-center space-x-2">
                <CheckCircle className="text-green-500" />
                <span className="text-gray-500">MRZ is clearly readable</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="text-green-500" />
                <span className="text-gray-500">MRZ is at the bottom</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="text-green-500" />
                <span className="text-gray-500">No glare or reflection</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="flex flex-1" />

      {/* Action buttons area */}
      {isSubmitting ? (
        <div className="flex flex-col justify-center items-center mb-6">
          <Hourglass height={"32"} width={"32"} color="#1E40AF" />
          <p className="text-gray-500 mt-8">Please wait while we upload your photo...</p>
        </div>
      ) : (
        <div className="px-6 mb-6">
          <button
            className="flex items-center justify-center w-full py-3 bg-blue-500 text-white rounded-xl font-medium text-base shadow-sm hover:bg-blue-600 transition-colors"
            onClick={onConfirm}
            disabled={isSubmitting}
          >
            Confirm
            <CircleCheck className="ml-2" />
          </button>
          <button
            className="flex items-center justify-center w-full py-3 text-blue-500 font-medium text-base mt-2 hover:text-blue-600 transition-colors"
            onClick={() => inputRef.current.click()}
          >
            Retake
            <Camera className="ml-2" />
          </button>
        </div>
      )}

      <input ref={inputRef} accept="image/*" type="file" capture="environment" hidden onChange={onChange} />

      <Footer />
    </div>
  );
};

export default CheckQuality;
