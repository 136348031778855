import React from "react";

const ExpiredOrInvalidLink = ({ errorMessage }) => {
  return (
    <div className="flex justify-center items-center min-h-screen p-4">
      <div className="bg-white p-6 rounded-lg shadow-md text-center">
        <h1 className="text-2xl text-gray-800">{errorMessage}</h1>
      </div>
    </div>
  );
};

export default ExpiredOrInvalidLink;
