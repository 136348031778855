import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import { CircleX, X } from "lucide-react";

const MultipleReject = ({ progressStates, onRetry }) => {
  const [isMyKadFrontFailed, setIsMyKadFrontFailed] = useState();
  const [isMyKadBackFailed, setIsMyKadBackFailed] = useState();
  const [isSelfieFailed, setIsSelfieFailed] = useState();
  const [isHologramFailed, setIsHologramFailed] = useState();

  useEffect(() => {
    if (progressStates.myKadFront === "Fail") {
      setIsMyKadFrontFailed(true);
    }
    if (progressStates.myKadBack === "Fail") {
      setIsMyKadBackFailed(true);
    }
    if (progressStates.selfieWithMyKad === "Fail") {
      setIsSelfieFailed(true);
    }
    if (progressStates.hologramDetection === "Fail") {
      setIsHologramFailed(true);
    }
  }, [progressStates]);

  return (
    <div className="bg-white h-full flex flex-col justify-start p-4 min-h-screen">
      <div className="bg-gray-100 p-8 rounded-2xl mt-[calc(100vh/16)] mb-8">
        <div className="flex flex-col items-center mb-8">
          <CircleX height="72" width="72" color="red" />
        </div>
        <div className="flex flex-col">
          <p className="text-2xl font-medium">Oops! We couldn't verify your identity</p>
          <p className="text-gray-500 text-md mt-4">
            There are <strong>multiple issues </strong>
            with your MyKad. Please verify and try again.
          </p>

          <ul className="space-y-2 my-8">
            {isMyKadFrontFailed && (
              <li className="flex items-start space-x-2">
                <X className="w-6 h-6 text-red-600" />
                <span>MyKad front</span>
              </li>
            )}
            {isMyKadBackFailed && (
              <li className="flex items-start space-x-2">
                <X className="w-6 h-6 text-red-600" />
                <span>MyKad back</span>
              </li>
            )}
            {isSelfieFailed && (
              <li className="flex items-start space-x-2">
                <X className="w-6 h-6 text-red-600" />
                <span>Selfie with MyKad</span>
              </li>
            )}
            {isHologramFailed && (
              <li className="flex items-start space-x-2">
                <X className="w-6 h-6 text-red-600" />
                <span>Hologram detection</span>
              </li>
            )}
          </ul>
          {<ul className="text-gray-500 text-md mt-4"></ul>}
        </div>
      </div>

      <div className="flex flex-1" />

      <button
        className="w-2/3 bg-blue-600 text-white py-3 px-4 rounded-full flex items-center justify-center space-x-2 mx-auto transition active:scale-95 duration-150 ease-in-out"
        onClick={onRetry}
      >
        <span>Try Again</span>
      </button>
      <Footer />
    </div>
  );
};

export default MultipleReject;
