import React from "react";
import Footer from "./Footer";
import { CircleCheck, Camera, CheckCircle, Pencil } from "lucide-react";
import { Hourglass } from "react-loader-spinner";

const CheckQuality = (props) => {
  const {
    useOCRForData,
    ocrName,
    ocrIDNumber,
    isSubmitting,
    myKadSide,
    imageBlob,
    onRetake,
    onConfirm,
    onEditName,
    onEditIDNumber,
  } = props;

  const inputRef = React.useRef(null);

  const onChange = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);

    reader.onload = async () => {
      onRetake(reader.result);
    };
  };

  return (
    <div className="p-4 rounded-t-xl bg-white flex flex-col h-full min-h-screen">
      <h2 className="text-xl font-bold text-center mb-2">Check your photo quality</h2>
      <div>
        <div className="flex flex-col items-center mt-4 mb-4">
          <img
            src={URL.createObjectURL(imageBlob)}
            className="h-48 md:h-48 lg:h-48 w-auto rounded-lg shadow-md shadow-black"
          />
          <p className="text-gray-500 font-bold text-center mt-2 flex items-center justify-center">
            MyKad {myKadSide === "front" ? "Front" : "Back"}
          </p>
        </div>

        {useOCRForData ? (
          <div className="bg-gray-50 rounded-xl p-4 mb-6">
            <ul className="space-y-2">
              <li className="flex items-center justify-center space-x-4">
                <span className="text-gray-800">{ocrName}</span>
                <button className="text-blue-500 hover:text-blue-600 transition-colors" onClick={onEditName}>
                  <Pencil size={18} />
                </button>
              </li>
              <li className="flex items-center justify-center space-x-4">
                <span className="text-gray-800">{ocrIDNumber}</span>
                <button className="text-blue-500 hover:text-blue-600 transition-colors" onClick={onEditIDNumber}>
                  <Pencil size={18} />
                </button>
              </li>
            </ul>
          </div>
        ) : null}

        <div className="bg-gray-50 rounded-xl p-4 mb-6">
          <p className="text-sm text-gray-500 mb-3 font-medium">PLEASE ENSURE:</p>
          <div className="space-y-4 mt-4">
            <ul className="space-y-2">
              <li className="flex items-center space-x-2">
                <CheckCircle className="text-green-500" />
                <span className="text-gray-800">The photo is clear and sharp</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="text-green-500" />
                <span className="text-gray-800">All corners of your MyKad are visible</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="text-green-500" />
                <span className="text-gray-800">There is no glare or reflection</span>
              </li>
              {useOCRForData ? (
                <li className="flex items-center space-x-2">
                  <CheckCircle className="text-green-500" />
                  <span className="text-gray-800">The name and ID number are correct</span>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>

      <div className="flex flex-1" />

      {/* Action buttons area */}
      {isSubmitting ? (
        <div className="flex flex-col justify-center items-center mb-6">
          <Hourglass height={"32"} width={"32"} color="#1E40AF" />
          <p className="text-gray-500 mt-8">Please wait while we upload your photo...</p>
        </div>
      ) : (
        <div className="px-6 mb-6">
          <button
            className="flex items-center justify-center w-full py-3 bg-blue-500 text-white rounded-xl font-medium text-base shadow-sm hover:bg-blue-600 transition-colors"
            onClick={onConfirm}
            disabled={isSubmitting}
          >
            Confirm
            <CircleCheck className="ml-2" />
          </button>
          <button
            className="flex items-center justify-center w-full py-3 text-blue-500 font-medium text-base mt-2 hover:text-blue-600 transition-colors"
            onClick={() => inputRef.current.click()}
          >
            Retake
            <Camera className="ml-2" />
          </button>
        </div>
      )}

      <input ref={inputRef} accept="image/*" type="file" capture="environment" hidden onChange={onChange} />

      <Footer />
      {/* </div> */}
      {/* </div> */}
      {/* <h2 className="text-xl font-bold mb-2">Check your photo quality</h2>
      <p className="text-gray-500 my-4 text-left max-w-prose">Please ensure:</p>

      <ul className="space-y-2">
        <li className="flex items-center space-x-2">
          <CircleCheck className="text-green-500" />
          <span className="text-gray-500">The photo is clear and sharp</span>
        </li>
        <li className="flex items-center space-x-2">
          <CircleCheck className="text-green-500" />
          <span className="text-gray-500">All corners of your MyKad are visible</span>
        </li>
        <li className="flex items-center space-x-2">
          <CircleCheck className="text-green-500" />
          <span className="text-gray-500">There is no glare or reflection</span>
        </li>
      </ul>

      <div className="flex flex-col items-center mt-8 mb-8">
        <img
          src={URL.createObjectURL(imageBlob)}
          className="h-48 md:h-64 lg:h-80 w-auto rounded-lg shadow-md shadow-black"
        />
        <p className="text-gray-500 font-bold text-center mt-2 flex items-center justify-center">
          MyKad {myKadSide === "front" ? "Front" : "Back"}
        </p>
      </div>

      <div className="h-8"></div>

      {isSubmitting ? (
        <div className="flex flex-col justify-center items-center">
          <Hourglass height={"32"} width={"32"} color="#1E40AF" />
          <p className="text-gray-500 mt-8">Please wait while we upload your photo...</p>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center space-y-4">
          <button
            className="w-2/3 bg-white border border-blue-600 text-blue-600 py-3 px-4 rounded-full flex items-center justify-center space-x-2 mx-auto hover:bg-blue-600 hover:text-white transition active:scale-95 duration-150 ease-in-out"
            onClick={() => inputRef.current.click()}
          >
            <span>Retake</span>
            <Camera />
          </button>

          <button
            className="w-2/3 bg-blue-600 text-white py-3 px-4 rounded-full flex items-center justify-center space-x-2 mx-auto transition active:scale-95 duration-150 ease-in-out"
            onClick={onConfirm}
            disabled={isSubmitting}
          >
            <span>Confirm</span>
            <CircleCheck />
          </button>
        </div>
      )}

      <input ref={inputRef} accept="image/*" type="file" capture="environment" hidden onChange={onChange} />

      <Footer /> */}
    </div>
  );
};

export default CheckQuality;
