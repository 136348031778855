import React from "react";
import logo from "../../../assets/images/kenal-color-transparent.png";

const Footer = () => {
  return (
    <footer className="flex items-center justify-center text-xs text-gray-400 bg-white px-4 py-2 mt-4">
      <span>Secured by</span>
      <img src={logo} alt="logo" className="ml-2" width={"12px"} />
      <span className="ml-1">Kenal e-KYC</span>
    </footer>
  );
};

export default Footer;
