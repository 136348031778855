import { useCallback, useContext } from "react";
import { AuthContext } from "../../App";
import { SERVER_HOST } from "../../constants/config";

const BaseAPIUrl = SERVER_HOST;

const useMyKadService = () => {
  const { authState } = useContext(AuthContext);

  const getValidationRecordFromToken = useCallback(
    async (token) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
        },
      };

      return fetch(`${BaseAPIUrl}/mykad-verification/mykad/${token}`, config).then((response) => response.json());
    },
    [authState]
  );

  const uploadFront = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
        },
        body: params,
      };

      return fetch(BaseAPIUrl + "/mykad-verification/mykad/upload-front", config).then((response) => response.json());
    },
    [authState]
  );

  const verifyFront = useCallback(
    async (params) => {
      const config = {
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(params),
      };

      return fetch(BaseAPIUrl + "/mykad-verification/mykad/verify-front", config).then((response) => response.json());
    },
    [authState]
  );

  const uploadBack = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
        },
        body: params,
      };

      return fetch(BaseAPIUrl + "/mykad-verification/mykad/upload-back", config).then((response) => response.json());
    },
    [authState]
  );

  const verifyBack = useCallback(
    async (params) => {
      const config = {
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(params),
      };

      return fetch(BaseAPIUrl + "/mykad-verification/mykad/verify-back", config).then((response) => response.json());
    },
    [authState]
  );

  const verifySelfieWithMyKad = useCallback(
    async (params) => {
      const config = {
        headers: {
          Authorization: "Bearer " + authState.token,
        },
        method: "post",
        body: params,
      };

      return fetch(BaseAPIUrl + "/mykad-verification/mykad/verify-selfie-with-mykad", config).then((response) =>
        response.json()
      );
    },
    [authState]
  );

  const verifyHologram = useCallback(
    async (params) => {
      const config = {
        headers: {
          Authorization: "Bearer " + authState.token,
        },
        method: "post",
        body: params,
      };

      return fetch(BaseAPIUrl + "/mykad-verification/mykad/verify-hologram", config).then((response) =>
        response.json()
      );
    },
    [authState]
  );

  const checkResult = useCallback(
    async (params) => {
      const config = {
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(params),
      };

      return fetch(BaseAPIUrl + "/mykad-verification/mykad/check-result", config).then((response) => response.json());
    },
    [authState]
  );

  const getMyKadRejectReason = useCallback(
    async (params) => {
      const config = {
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(params),
      };

      return fetch(BaseAPIUrl + "/mykad-verification/mykad/reject-reason", config).then((response) => response.json());
    },
    [authState]
  );

  return {
    getValidationRecordFromToken,
    uploadFront,
    verifyFront,
    uploadBack,
    verifyBack,
    verifySelfieWithMyKad,
    verifyHologram,
    checkResult,
    getMyKadRejectReason,
  };
};

export { useMyKadService };
