import React, { useState, useEffect } from "react";
import EnterData from "./components/EnterData";
import Guide from "./components/Guide";
import Upload from "./components/Upload";
import SelfieWithMyKad from "./components/SelfieWithMyKad";
import Liveness from "./components/Liveness";
import Success from "./components/Success";
import { useMyKadService } from "./useMyKadService";
import FailVerifyMyKad from "./components/FailVerifyMyKad";
import ExceedMaxRetry from "./components/ExceedMaxRetry";
import FailVerifyLivess from "./components/FailVerifyLiveness";
import { useLocation } from "react-router-dom";
import ExpiredOrInvalidLink from "./components/ExpiredOrInvalidLink";
import DetectHologram from "./components/DetectHologram";
import { set } from "react-hook-form";
import FailVerifySelfieWithMyKad from "./components/FailVerifySelfieWithMyKad";

export const EKYCMyKadContext = React.createContext();

const MAX_RETRIES = 3;

const MyKad = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const { start, verify, getValidationRecordFromToken, verifySelfieWithMyKad } = useMyKadService();

  const [submitting, setSubmitting] = useState(false);
  const [id, setId] = useState(null);
  const [config, setConfig] = useState(null);
  const [step, setStep] = useState("enterData");
  const [errorMessage, setErrorMessage] = useState(null);
  const [possibleFailReasonList, setPossibleFailReasonList] = useState([]);
  const [customerName, setCustomerName] = useState(null);
  const [myKadFrontBase64, setMyKadFrontBase64] = useState(null);
  const [myKadBackBase64, setMyKadBackBase64] = useState(null);
  const [icNumberFront, setIcNumberFront] = useState(null);
  const [icNumberBack, setIcNumberBack] = useState(null);
  const [selfieWithMyKadBase64, setSelfieWithMyKadBase64] = useState(null);
  const [profilePhotoBase64, setProfilePhotoBase64] = useState(null);
  const [liveFacePhotoBase64, setLiveFacePhotoBase64] = useState(null);
  const [redirectURL, setRedirectURL] = useState(null);

  useEffect(() => {
    if (token) {
      getValidationRecordFromToken(token).then((res) => {
        if (res.status === "success") {
          const redirectUrl = res.data.RedirectURL;

          if (res.data.StepTwoResult === "Pass" && redirectUrl) {
            window.location.href = redirectUrl;
            return;
          }

          setRedirectURL(redirectUrl);
          setId(res.data._default + "." + res.data._id);
          setConfig(res.data.config);

          if (res.data.StepOneResult === undefined || res.data.StepOneResult === "Fail") {
            setStep("guide");
          } else if (res.data.StepOneResult === "Pass" && res.data.StepTwoResult !== "Pass") {
            setStep("liveness");
          }
        } else {
          setStep("expiredOrInvalidLink");
          setErrorMessage(res.message);
        }
      });
    }
  }, [token]);

  const onSubmit = (data) => {
    setSubmitting(true);
    start({
      name: data.name,
      idNumber: data.idNumber,
      docType: "MyKad",
    }).then((res) => {
      if (res.status === "success") {
        setId(res.data.sessionId);
        setConfig(res.data.config);
        setStep("guide");
      }
      setSubmitting(false);
    });
  };

  const handleVerify = async () => {
    setSubmitting(true);
    verify({
      id: id,
    })
      .then((res) => {
        if (res.status === "success") {
          localStorage.removeItem(`retryCount_${id}`);

          if (config && config["Selfie With MyKad"]) {
            setStep("selfieWithMyKad");
          } else if (config && config["Hologram Detection"]) {
            setStep("hologramDetection");
          } else {
            setStep("liveness");
          }
        } else {
          setStep("failVerifyMyKad");
          setErrorMessage(res.message);
          setCustomerName(res.data.CustomerName);
          setMyKadFrontBase64(res.data.MyKadFrontBase64);
          setMyKadBackBase64(res.data.MyKadBackBase64);
          setIcNumberFront(res.data.NRICFront);
          setIcNumberBack(res.data.NRICBack);
          setPossibleFailReasonList(res.data.FailReasonAndSolutionList);
        }
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const handleSelfieWithMyKad = async (base64) => {
    setSubmitting(true);

    try {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/jpeg" });

      const formData = new FormData();
      formData.append("id", id);
      formData.append("file", blob, "document.jpg");

      const response = await verifySelfieWithMyKad(formData);

      if (response.status === "success") {
        setErrorMessage(null);

        if (config && config["Hologram Detection"]) {
          setStep("hologramDetection");
        } else {
          setStep("liveness");
        }
      } else {
        setErrorMessage(response.message);
        setCustomerName(response.data?.CustomerName);
        setIcNumberFront(response.data?.NRICFront);
        setSelfieWithMyKadBase64(base64);
        setPossibleFailReasonList(response.data?.FailReasonAndSolutionList || []);
        setStep("failVerifySelfieWithMyKad");
      }
      setSubmitting(false);
    } catch (error) {
      console.error(error);
      setSubmitting(false);
      setErrorMessage("An error occurred during verification. Please try again.");
      setStep("failVerifySelfieWithMyKad");
    }
  };

  const renderSteps = () => {
    switch (step) {
      case "enterData":
        return <EnterData onNext={onSubmit} isSubmitting={submitting} />;

      case "guide":
        return <Guide onNext={() => setStep("upload")} />;

      case "upload":
        return <Upload onSubmit={handleVerify} isSubmitting={submitting} />;

      case "failVerifyMyKad":
        return (
          <FailVerifyMyKad
            errorMessage={errorMessage}
            myKadFrontBase64Image={myKadFrontBase64}
            myKadBackBase64Image={myKadBackBase64}
            icNumberFront={icNumberFront}
            icNumberBack={icNumberBack}
            customerName={customerName}
            reasonAndSolutionList={possibleFailReasonList}
            onRetry={() => {
              const newCount = parseInt(localStorage.getItem(`retryCount_${id}`) || "0", 10) + 1;
              localStorage.setItem(`retryCount_${id}`, newCount.toString());
              if (newCount >= MAX_RETRIES) {
                setStep("exceededMaxRetries");
                return;
              }

              setStep("upload");
              setErrorMessage(null);
              setCustomerName(null);
              setMyKadFrontBase64(null);
              setMyKadBackBase64(null);
              setIcNumberFront(null);
              setIcNumberBack(null);
              setPossibleFailReasonList([]);
            }}
          />
        );

      case "selfieWithMyKad":
        return <SelfieWithMyKad onSubmit={handleSelfieWithMyKad} isSubmitting={submitting} />;

      case "failVerifySelfieWithMyKad":
        return (
          <FailVerifySelfieWithMyKad
            errorMessage={errorMessage}
            selfieWithMyKadBase64Image={selfieWithMyKadBase64}
            icNumberFront={icNumberFront}
            customerName={customerName}
            reasonAndSolutionList={possibleFailReasonList}
            onRetry={() => {
              setStep("selfieWithMyKad");
              setErrorMessage(null);
              setCustomerName(null);
              setSelfieWithMyKadBase64(null);
              setIcNumberFront(null);
              setPossibleFailReasonList([]);
            }}
          />
        );

      case "hologramDetection":
        return (
          <DetectHologram
            onNext={() => {
              setStep("liveness");
            }}
          />
        );

      case "liveness":
        return (
          <Liveness
            onSuccess={({ profilePhotoBase64, liveFacePhotoBase64 }) => {
              setProfilePhotoBase64(profilePhotoBase64);
              setLiveFacePhotoBase64(liveFacePhotoBase64);
              setErrorMessage(null);
              setStep("success");
              if (redirectURL) {
                window.open(redirectURL, "_self");
              }
            }}
            onFail={(result) => {
              setStep("failVerifyLiveness");
              setErrorMessage(result.failMessage);
            }}
          />
        );
      case "failVerifyLiveness":
        return (
          <FailVerifyLivess
            profilePhotoBase64={profilePhotoBase64}
            liveFacePhotoBase64={liveFacePhotoBase64}
            onRetry={() => {
              setStep("liveness");
              setProfilePhotoBase64(null);
              setLiveFacePhotoBase64(null);
            }}
          />
        );
      case "success":
        return (
          <Success
            profilePhotoBase64={profilePhotoBase64}
            liveFacePhotoBase64={liveFacePhotoBase64}
            onStartAgain={() => {
              setStep("enterData");
              setId(null);
              setProfilePhotoBase64(null);
              setLiveFacePhotoBase64(null);
            }}
          />
        );

      case "exceededMaxRetries":
        return <ExceedMaxRetry />;

      case "expiredOrInvalidLink":
        return <ExpiredOrInvalidLink errorMessage={errorMessage} />;

      default:
        return <EnterData onSubmit={onSubmit} isSubmitting={submitting} />;
    }
  };

  return (
    <EKYCMyKadContext.Provider
      value={{
        id,
        config,
      }}
    >
      <div className="bg-gray-100">{renderSteps()}</div>
    </EKYCMyKadContext.Provider>
  );
};

export default MyKad;
