import React from "react";
import { Heading, Text, Button, Box, Card } from "@radix-ui/themes";
import { Check, TriangleAlert, Circle } from "lucide-react";
import myKadGood from "../../../assets/images/icguideline-good.png";
import myKadBad from "../../../assets/images/icguideline-wrong.png";

const Guide = ({ onNext }) => {
  return (
    <div className="min-h-screen p-4 flex items-center justify-center">
      <Card className="w-full max-w-md">
        <Box className="text-center mb-4">
          <Heading size="5">MyKad Photo Guidelines</Heading>
          <Text size={"2"}>Follow these instructions to capture your MyKad photos</Text>
        </Box>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-col items-center justify-center">
            <h4 className="font-medium text-green-600 mb-2">Good</h4>
            <div className="flex space-x-4">
              <img
                src={myKadGood}
                alt="Correct MyKad photo example"
                width={"auto"}
                height={80}
                className="rounded-lg shadow-md"
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <h4 className="font-medium text-red-600 mb-2">Bad</h4>
            <div className="flex space-x-4">
              <img
                src={myKadBad}
                alt="Incorrect passport photo example"
                width={"auto"}
                height={180}
                className="rounded-lg shadow-md"
              />
            </div>
          </div>
        </div>

        <div className="bg-white shadow-lg rounded-lg overflow-hidden my-8">
          <div className="px-4 py-2 bg-blue-600">
            <h4 className="font-semibold text-white">Important Guidelines:</h4>
          </div>
          <div className="p-4 space-y-4">
            {[
              { icon: Check, text: "Ensure good lighting conditions" },
              { icon: Check, text: "Make sure all text is clearly <strong>readable</strong>" },
              { icon: Check, text: "Make sure profile photos are not covered up by <strong>hologram</strong>" },
              { icon: Check, text: "Avoid glare, reflections or shadows on MyKad" },
              { icon: Check, text: "Ensure MyKad fills <strong>most of the frame</strong> in the camera view" },
            ].map((item, index) => (
              <div key={index} className="flex items-center space-x-3">
                <item.icon className="w-6 h-6 text-green-600" />
                {/* <span>{item.text}</span> */}
                <span dangerouslySetInnerHTML={{ __html: item.text }} />
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
          <div className="px-4 py-2 bg-red-600">
            <h4 className="font-semibold text-white">DO NOT:</h4>
          </div>
          <div className="p-4 space-y-4">
            {[
              { icon: TriangleAlert, text: "Take blurry or unclear photos" },
              { icon: TriangleAlert, text: "Cover any part of the MyKad" },
              { icon: TriangleAlert, text: "Take photo at an angle" },
              { icon: TriangleAlert, text: "Have <strong>hologram</strong> covering up the information" },
            ].map((item, index) => (
              <div key={index} className="flex items-center space-x-3">
                <item.icon className="w-6 h-6 text-red-600" />
                <span dangerouslySetInnerHTML={{ __html: item.text }} />
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
          <div className="px-4 py-2 bg-yellow-800">
            <h4 className="font-semibold text-white">Common issues:</h4>
          </div>
          <div className="p-4 space-y-4">
            {[
              { icon: Circle, text: "MyKad is not properly aligned and cropped" },
              { icon: Circle, text: "Identity fields are covered by holograms or glares" },
              { icon: Circle, text: "Texts are not clear or blurry" },
            ].map((item, index) => (
              <div key={index} className="flex items-center space-x-3">
                <item.icon className="w-6 h-6 text-yellow-800" />
                <span>{item.text}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="flex items-center justify-center">
          <Button className="w-full group" onClick={onNext}>
            I Understand, Continue to Camera
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Guide;
