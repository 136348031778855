import React, { useEffect } from "react";
import { MagnifyingGlass } from "react-loader-spinner";
import Footer from "./Footer";
import { usePassportService } from "../usePassportService";

const Verifying = (props) => {
  const { id, progressStates, hasPassportCheckCompleted, onPass, onFail } = props;

  const { checkResult } = usePassportService();

  useEffect(() => {
    if (hasPassportCheckCompleted) {
      checkResult({ id }).then((res) => {
        if (res.status === "success") {
          if (res.data.overallStatus === "Pass") {
            onPass(res.data);
          } else {
            onFail(res.data);
          }
        } else {
          onFail(res.data);
        }
      });
    }
  }, [hasPassportCheckCompleted]);

  return (
    <div className="bg-white h-full flex flex-col justify-start p-8 min-h-screen">
      <div className="bg-gray-100 p-8 rounded-2xl mt-[calc(100vh/16)]">
        <div className="flex flex-col">
          <p className="text-2xl font-medium">
            Verifying your
            <br />
            <span className="text-blue-700">document.</span>
          </p>
          <p className="text-gray-500 text-md mt-4">Please wait while we verify your document...</p>
        </div>
        <div className="flex flex-col items-center mt-8">
          <MagnifyingGlass height="80" width="80" color="#1E3A8A" />
        </div>
      </div>

      <div className="flex flex-1" />

      <Footer />
    </div>
  );
};

export default Verifying;
