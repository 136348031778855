import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import { CircleCheck, RefreshCcw, Camera, CheckCircle, ArrowLeftRight } from "lucide-react";
import { Hourglass } from "react-loader-spinner";

const CheckQualitySelfieWithMyKad = (props) => {
  const { isSubmitting, imageBuffer, onRetake, onConfirm } = props;

  const inputRef = React.useRef(null);

  const [imageBlob, setImageBlob] = useState();

  const onChange = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);

    reader.onload = async () => {
      onRetake(reader.result);
    };
  };

  useEffect(() => {
    if (imageBuffer) {
      setImageBlob(new Blob([imageBuffer], { type: "image/jpeg" }));
    }
  }, [imageBuffer]);

  const onMirror = () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.src = URL.createObjectURL(imageBlob);
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.translate(img.width, 0);
      ctx.scale(-1, 1);
      ctx.drawImage(img, 0, 0);

      canvas.toBlob((blob) => {
        setImageBlob(blob);
      }, "image/jpeg");
    };
  };

  return (
    <div className="p-4 rounded-t-xl bg-white flex flex-col h-full min-h-screen">
      {/* Header section */}
      <h2 className="text-xl font-bold text-center mb-2">
        {" "}
        Review your
        <span className="text-blue-700"> Selfie with MyKad</span>
      </h2>

      {/* Content area with Apple-style spacing */}
      <div>
        {/* Image container with soft shadow and rounded corners */}
        <div className="flex flex-col items-center mt-4 mb-4">
          {imageBlob ? (
            <img
              src={URL.createObjectURL(imageBlob)}
              className="h-60 md:h-64 lg:h-80 w-auto rounded-lg shadow-md shadow-black"
            />
          ) : null}
          <p className="text-gray-500 font-bold text-center mt-2 flex items-center justify-center">
            Your Selfie with MyKad
          </p>

          {!isSubmitting ? (
            <div className="flex items-center mt-4">
              <button
                className="bg-white border border-gray-300 text-gray-500 py-2 px-4 rounded-full flex items-center "
                onClick={onMirror}
              >
                Flip
                <ArrowLeftRight className="ml-2" />
              </button>
            </div>
          ) : null}
        </div>

        {/* Requirements section with iOS-style icons */}
        <div className="bg-gray-50 rounded-xl p-4 mb-6">
          <p className="text-sm text-gray-500 mb-3 font-medium">PLEASE VERIFY THESE IMPORTANT DETAILS:</p>
          <div className="space-y-4 mt-4">
            <ul className="space-y-2">
              <li className="flex items-center space-x-2">
                <CheckCircle className="text-green-500" />
                <span className="text-gray-500">Your MyKad is clearly visible and readable</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="text-green-500" />
                <span className="text-gray-500">Your face is clearly visible and not covered</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="text-green-500" />
                <span className="text-gray-500">No one else appears in the photo</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="text-green-500" />
                <span className="text-gray-500">
                  The image is correctly oriented <br />
                  (not mirrored)
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="flex flex-1" />

      {/* Action buttons area */}
      {isSubmitting ? (
        <div className="flex flex-col justify-center items-center mb-6">
          <Hourglass height={"32"} width={"32"} color="#1E40AF" />
          <p className="text-gray-500 mt-8">Please wait while we upload your photo...</p>
        </div>
      ) : (
        <div className="px-6 mb-6">
          <button
            className="flex items-center justify-center w-full py-3 bg-blue-500 text-white rounded-xl font-medium text-base shadow-sm hover:bg-blue-600 transition-colors"
            onClick={() => onConfirm(imageBlob)}
            disabled={isSubmitting}
          >
            Confirm
            <CircleCheck className="ml-2" />
          </button>
          <button
            className="flex items-center justify-center w-full py-3 text-blue-500 font-medium text-base mt-2 hover:text-blue-600 transition-colors"
            onClick={() => inputRef.current.click()}
          >
            Retake
            <Camera className="ml-2" />
          </button>
        </div>
      )}

      <input ref={inputRef} accept="image/*" type="file" capture="environment" hidden onChange={onChange} />

      <Footer />
      {/* </div> */}
      {/* // </div> */}
      {/* <h2 className="text-xl font-bold mb-2">
        Review your
        <span className="text-blue-700"> selfie with MyKad</span>
      </h2>
      <p className="text-gray-500 my-4 text-left max-w-prose">Please verify these important details</p>

      <ul className="space-y-2">
        <li className="flex items-center space-x-2">
          <CircleCheck className="text-green-500" />
          <span className="text-gray-500">Your MyKad is clearly visible and readable</span>
        </li>
        <li className="flex items-center space-x-2">
          <CircleCheck className="text-green-500" />
          <span className="text-gray-500">Your face is clearly visible and not covered</span>
        </li>
        <li className="flex items-center space-x-2">
          <CircleCheck className="text-green-500" />
          <span className="text-gray-500">No one else appears in the photo</span>
        </li>
        <li className="flex items-center space-x-2">
          <CircleCheck className="text-green-500" />
          <span className="text-gray-500">The image is correctly oriented (not mirrored)</span>
        </li>
      </ul>

      <div className="flex flex-col items-center mt-8 mb-8">
        {imageBlob ? (
          <img
            src={URL.createObjectURL(imageBlob)}
            className="h-60 md:h-64 lg:h-80 w-auto rounded-lg shadow-md shadow-black"
          />
        ) : null}
        <p className="text-gray-500 font-bold text-center mt-2 flex items-center justify-center">
          Your Selfie with MyKad
        </p>

        {!isSubmitting ? (
          <div className="flex items-center mt-4">
            <button
              className="bg-white border border-gray-300 text-gray-500 py-2 px-4 rounded-full flex items-center "
              onClick={onMirror}
            >
              <RefreshCcw />
            </button>
          </div>
        ) : null}
      </div>

      {isSubmitting ? (
        <div className="flex flex-col justify-center items-center">
          <Hourglass height={"32"} width={"32"} color="#1E40AF" />
          <p className="text-gray-500 mt-8">Please wait while we upload your photo...</p>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center space-y-4">
          <button
            className="w-2/3 bg-white border border-blue-600 text-blue-600 py-3 px-4 rounded-full flex items-center justify-center space-x-2 mx-auto hover:bg-blue-600 hover:text-white transition active:scale-95 duration-150 ease-in-out"
            onClick={() => inputRef.current.click()}
          >
            <span>Retake</span>
            <Camera />
          </button>

          <button
            className="w-2/3 bg-blue-600 text-white py-3 px-4 rounded-full flex items-center justify-center space-x-2 mx-auto transition active:scale-95 duration-150 ease-in-out"
            onClick={() => onConfirm(imageBlob)}
            disabled={isSubmitting}
          >
            <span>Confirm</span>
            <CircleCheck />
          </button>
        </div>
      )}

      <input ref={inputRef} accept="image/*" type="file" capture="environment" hidden onChange={onChange} />

      <Footer /> */}
    </div>
  );
};

export default CheckQualitySelfieWithMyKad;
