import React, { useState, useEffect } from "react";
import { useWindowSize } from "react-use";
import Confetti from "react-confetti";
import Footer from "./Footer";
import { CircleCheck } from "lucide-react";

const Success = ({ onDone }) => {
  const { width, height } = useWindowSize();

  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          status: "success",
          message: "Verification Successful",
        })
      );
    }

    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler(
        "KenalFlutterWebViewChannel",
        JSON.stringify({
          status: "success",
          message: "Verification Successful",
        })
      );
    }

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      onDone();
    }
  }, [countdown]);

  return (
    <div className="bg-white h-full flex flex-col justify-start p-4 min-h-screen">
      <Confetti width={width} height={height} numberOfPieces={20} />
      <div className="bg-gray-100 p-8 rounded-2xl mt-[calc(16)] mb-8">
        <div className="flex flex-col">
          <p className="text-2xl font-medium">Verification Complete!</p>
          <p className="text-gray-500 text-md mt-4">
            Thank you! Your identity has been <strong>successfully</strong> verified.
          </p>
        </div>
        <div className="flex flex-col items-center mt-8">
          <CircleCheck height="72" width="72" color="green" />
        </div>

        <div className="flex flex-col items-center mt-8">
          You'll be continuing the process in {countdown} seconds...
        </div>
      </div>

      <div className="flex flex-1" />

      <Footer />
    </div>
  );
};

export default Success;
