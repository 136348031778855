import React from "react";
import { Heading, Text, Button, Box, Card } from "@radix-ui/themes";
import { Dock, User } from "lucide-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";

const schema = object().shape({
  name: string()
    .matches(/^[a-zA-Z\s\-\.\'\/@]*$/, "Invalid name. No special characters allowed.")
    .required("Name is required."),
  idNumber: string()
    .required("MyKad number is required")
    .matches(/^\d{12}$/, "Invalid MyKad number") // The ID number must be 12 digits long
    .matches(/^[0-9]{2}/, "Invalid MyKad number") // The first two digits are the birth year, so it must be between 00 and 99
    .matches(/^\d{2}(0[1-9]|1[0-2])/, "Invalid MyKad number") // The 3rd and 4th digits are the birth month, so it must be between 01 and 12
    .matches(/^\d{4}(0[1-9]|[12][0-9]|3[01])/, "Invalid MyKad number") // The next two digits are the birth day, so it must be between 01 and 31
    .matches(/^\d{6}(0[1-9]|1[0-6]|2[1-9]|[3-5][0-8]|82)/, "Invalid MyKad number") // The next two digits are the birth place code, so it must be between 01 to 16, 21 to 58, or 82
    .required("This field is required"),
});

const EnterData = ({ onNext, isSubmitting }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <div className="min-h-screen p-4 flex items-center justify-center">
      <Card className="w-full max-w-md">
        <Box className="text-center mb-4">
          <div className="flex items-center justify-center w-16 h-16 mx-auto mb-2 bg-primary/5 rounded-full">
            <Dock className="w-8 h-8 text-primary" />
          </div>
          <Heading size="4">MyKad eKYC Verification</Heading>
          <Text>Please enter your details as shown in your MyKad</Text>
        </Box>

        <Box>
          <form onSubmit={handleSubmit(onNext)} className="space-y-4">
            <div className="space-y-2">
              <Text htmlFor="fullName" className="flex items-center gap-2">
                <User className="w-4 h-4" />
                Full Name (as per IC)
              </Text>
              <Box>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                    <>
                      <input
                        value={value}
                        type="text"
                        placeholder="Enter your full name as per IC"
                        style={{
                          width: "100%",
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid var(--gray-7)",
                        }}
                        onChange={(e) => {
                          // if e.target.value is special character, it will not be set to the input.
                          if (/^[a-zA-Z\s\-\.\'\/@]*$/.test(e.target.value)) {
                            onChange(e.target.value.toUpperCase());
                          }
                        }}
                      />
                      {error && (
                        <Text size="2" color="red" mt="1">
                          {error.message}
                        </Text>
                      )}
                    </>
                  )}
                />
              </Box>
            </div>
            <div className="space-y-2">
              <Text htmlFor="passportNumber" className="flex items-center gap-2">
                <Dock className="w-4 h-4" />
                IC Number (without dashes)
              </Text>

              <Box>
                <Controller
                  name="idNumber"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                    <>
                      <input
                        value={value}
                        type="text"
                        placeholder="Enter IC number"
                        style={{
                          width: "100%",
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid var(--gray-7)",
                        }}
                        onChange={(e) => {
                          if (/^\d*$/.test(e.target.value)) {
                            onChange(e.target.value);
                          }
                        }}
                      />
                      {error && (
                        <Text size="2" color="red" mt="1">
                          {error.message}
                        </Text>
                      )}
                    </>
                  )}
                />
              </Box>
            </div>
            <Button type="submit" className="w-full group" loading={isSubmitting}>
              Continue
            </Button>
          </form>
        </Box>
      </Card>
    </div>
  );
};

export default EnterData;
