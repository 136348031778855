import React, { useRef } from "react";
import Footer from "./Footer";
import { ChevronRight } from "lucide-react";
import SampleImg from "../../../assets/images/HologramExample.jpg";

const GuidelineHologramDetection = (props) => {
  const { onNext } = props;

  const inputRef = useRef(null);

  const onChange = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);

    reader.onload = async () => {
      onNext(reader.result);
    };
  };

  return (
    <div className="p-4 rounded-t-xl bg-white flex flex-col h-full min-h-screen">
      {/* Title */}
      <h2 className="text-xl font-bold mb-4 text-center">
        Capture the
        <span className="text-blue-700"> Hologram</span> <br />
        on Your <span className="text-blue-700">MyKad</span>
      </h2>
      <p className="text-gray-500 mb-4 md:mb-8 text-left max-w-prose">
        Please take a photo of your MyKad showing the hologram area clearly. This helps us verify your MyKad's
        authenticity.
      </p>

      <p className="text-gray-500 my-4 text-left max-w-prose">Please ensure:</p>

      <ul className="space-y-2">
        <li className="flex items-center space-x-2">
          <ChevronRight className="text-blue-500" />
          <span className="text-gray-500">Position your entire MyKad within the camera</span>
        </li>
        <li className="flex items-center space-x-2">
          <ChevronRight className="text-blue-500" />
          <span className="text-gray-500">Ensure the hologram is clearly visible</span>
        </li>
      </ul>

      <div className="flex items-center justify-center mb-16 mt-8">
        <img
          src={SampleImg}
          alt="Sample of how to take a good picture of your IC"
          width={"auto"}
          height={"140px"}
          style={{ maxHeight: "180px" }}
          className="shadow-md shadow-black"
        />
      </div>

      <div className="flex flex-1" />

      <button
        className="w-2/3 bg-blue-600 text-white py-3 px-4 rounded-full flex items-center justify-center space-x-2 mx-auto transition active:scale-95 duration-150 ease-in-out"
        onClick={() => inputRef.current.click()}
      >
        <span>Continue</span>
        <ChevronRight className="w-5 h-5" />
      </button>

      <input ref={inputRef} accept="image/*" type="file" capture="environment" hidden onChange={onChange} />

      <Footer />
    </div>
  );
};

export default GuidelineHologramDetection;
