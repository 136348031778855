import React, { useState, useEffect } from "react";
import { MagnifyingGlass } from "react-loader-spinner";
import Footer from "./Footer";
import { useMyKadService } from "../useMyKadService";

const Verifying = (props) => {
  const {
    id,
    progressStates,
    workflowConfig,
    hasMyKadFrontCheckCompleted,
    hasMyKadBackCheckCompleted,
    hasSelfieWithMyKadCheckCompleted,
    hasHologramDetectionCheckCompleted,
    onPass,
    onFail,
  } = props;

  const { checkResult } = useMyKadService();

  const [startVerify, setStartVerify] = useState(false);

  useEffect(() => {
    const needsSelfieWithMyKad = workflowConfig["Selfie With MyKad"];
    const needsHologramDetection = workflowConfig["Hologram Detection"];

    const isMyKadChecksCompleted =
      (hasMyKadFrontCheckCompleted || progressStates.myKadFront === "Pass") &&
      (hasMyKadBackCheckCompleted || progressStates.myKadBack === "Pass");
    const isSelfieWithMyKadCompleted = needsSelfieWithMyKad
      ? hasSelfieWithMyKadCheckCompleted || progressStates.selfieWithMyKad === "Pass"
      : true;
    const isHologramDetectionCompleted = needsHologramDetection
      ? hasHologramDetectionCheckCompleted || progressStates.hologramDetection === "Pass"
      : true;

    if (isMyKadChecksCompleted && isSelfieWithMyKadCompleted && isHologramDetectionCompleted) {
      setStartVerify(true);
    }
  }, [
    workflowConfig,
    hasMyKadFrontCheckCompleted,
    hasMyKadBackCheckCompleted,
    hasSelfieWithMyKadCheckCompleted,
    hasHologramDetectionCheckCompleted,
  ]);

  useEffect(() => {
    if (progressStates.myKadFront === "Pending" || progressStates.myKadBack === "Pending") {
      console.log("Start verify");
      setStartVerify(true);
    }
  }, [progressStates]);

  useEffect(() => {
    if (startVerify) {
      verifyDocument();
    }
  }, [startVerify]);

  const verifyDocument = () => {
    checkResult({
      id,
    }).then((res) => {
      if (res.status === "success") {
        if (res.data.overallStatus === "Pass") {
          onPass(res.data);
        } else {
          onFail(res.data);
        }
      } else {
        onFail();
      }
      setStartVerify(false);
    });
  };

  return (
    <div className="bg-white h-full flex flex-col justify-start p-8 min-h-screen">
      <div className="bg-gray-100 p-8 rounded-2xl mt-[calc(100vh/16)]">
        <div className="flex flex-col">
          <p className="text-2xl font-medium">
            Verifying your
            <br />
            <span className="text-blue-700">MyKad.</span>
          </p>
          <p className="text-gray-500 text-md mt-4">
            Please wait a moment while our secure system processes your information...
          </p>
        </div>
        <div className="flex flex-col items-center mt-8">
          <MagnifyingGlass height="80" width="80" color="#1E3A8A" />
        </div>
      </div>

      <div className="flex flex-1" />

      <Footer />
    </div>
  );
};

export default Verifying;
