import { useEffect, useState } from "react";

export default function EditIDNumberModal({ isOpen, onClose, onSave, initialIDNumber }) {
  const [idNumber, setIDNumber] = useState(initialIDNumber);

  useEffect(() => {
    setIDNumber(initialIDNumber);
  }, [initialIDNumber]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-semibold mb-4">Edit IC Number</h2>
        <input
          type="number"
          value={idNumber}
          onChange={(e) => {
            // Only allow numeric characters
            let newIDNumber = e.target.value.replace(/\D/g, "");
            setIDNumber(newIDNumber);
          }}
          className="w-full border p-2 rounded-md mb-4"
        />
        <div className="flex justify-end space-x-2">
          <button onClick={onClose} className="px-4 py-2 bg-gray-300 rounded-md">
            Cancel
          </button>
          <button onClick={() => onSave(idNumber)} className="px-4 py-2 bg-blue-500 text-white rounded-md">
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
