import React, { useState, useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import ScanbotSDK from "scanbot-web-sdk";

const UploadPhoto = (props) => {
  const { imageBuffer, onSuccess, onError } = props;

  const [sdk, setSdk] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const initializeSdk = async () => {
      const sdk = await ScanbotSDK.initialize({ licenseKey: "" });

      setSdk(sdk);
    };

    initializeSdk();

    return () => {
      if (sdk) {
        setSdk(null);
      }
    };
  }, []);

  useEffect(() => {
    if (sdk && imageBuffer) {
      detectDocument(imageBuffer);
    }
    if (imageBuffer) {
      const blob = new Blob([imageBuffer], { type: "image/jpeg" });
      const url = URL.createObjectURL(blob);
      setImageUrl(url);

      // Clean up the object URL when the component unmounts or when imageBuffer changes
      return () => URL.revokeObjectURL(url);
    }
  }, [sdk, imageBuffer]);

  const detectDocument = async (imageBuffer) => {
    // First, we detect the document.
    const result = await sdk.detectDocument(imageBuffer, 75, 60);
    if (result.success !== true) {
      return onError("Invalid image. Please try again.");
    }
    if (result.detectionStatus === "NotAcquired" || result.detectionStatus === "Error_NothingDetected") {
      return onError("No document detected. Please try again.");
    }
    if (result.detectionStatus === "OK_BadAngles") {
      return onError("Bad angles. Please try again.");
    }
    if (result.detectionStatus === "Error_Brightness") {
      return onError("Bad brightness. Please try again.");
    }
    if (result.detectionStatus === "Error_Noise") {
      return onError("Too much noise. Please try again.");
    }
    if (result.detectionStatus === "OK_OffCenter") {
      return onError("Document is off center. Please try again.");
    }
    if ("OK_SmallSize" === result.detectionStatus) {
      return onError("Image is too small. Please move closer to the document.");
    }
    const cropped = await sdk.cropAndRotateImageCcw(imageBuffer, result.polygon, 0);

    // Next, we crop the MRZ area. We use normalized points to crop the MRZ area. We assume that the MRZ area is at the bottom of the document.
    // And the document is a two-page document.
    const imageProcessor = await sdk.createImageProcessor(cropped);

    // normalized points is between 0 and 1
    const normalizedMrzPoints = [
      { x: 0, y: 1 - 0.13 },
      { x: 1, y: 1 - 0.13 },
      { x: 1, y: 1 },
      { x: 0, y: 1 },
    ];

    await imageProcessor.crop(normalizedMrzPoints);

    const mrzImage = await imageProcessor.processedImage();

    // Lastly, we perform OCR on the MRZ area.
    const ocr = await sdk.createOcrEngine();
    const ocrResult = await ocr.performOcr(mrzImage);

    await ocr.release();
    await imageProcessor.release();

    // Return the original image, cropped document, mrz image, and OCR result.
    const croppedFile = new Blob([cropped], { type: "image/jpeg" });
    const originalFile = new Blob([imageBuffer], { type: "image/jpeg" });
    const mrzFile = new Blob([mrzImage], { type: "image/jpeg" });
    const mrzTextArray = ocrResult.ocrElement ? ocrResult.ocrElement.text.split("\n") : [];

    if (mrzTextArray.length !== 2) {
      return onError("MRZ not detected. Please take a closer photo and try again.");
    }

    onSuccess({
      croppedImageBlob: croppedFile,
      originalImageBlob: originalFile,
      mrzImageBlob: mrzFile,
      mrzTextArray: mrzTextArray,
    });
  };

  return sdk ? (
    <div className="fixed inset-0 flex items-center justify-center bg-black">
      {imageUrl && (
        <img src={imageUrl} alt="Uploaded" className="mx-auto my-auto max-w-full max-h-full object-contain filter" />
      )}
      {/* Scanning line overlay */}
      <div className="absolute inset-0">
        <div className="scanner" />
      </div>
      {/* Centered text overlay */}
      <div className="absolute inset-0 flex items-center justify-center">
        <p className="text-white font-semibold text-center">Please wait while we are processing your photo...</p>
      </div>
      <style jsx>{`
        .scanner {
          position: absolute;
          width: 100%;
          height: 4px; /* Line thickness */
          background: rgba(0, 255, 0, 0.8);
          animation: scan 2s linear infinite;
        }
        @keyframes scan {
          0% {
            top: 0;
          }
          100% {
            top: calc(100% - 4px);
          }
        }
      `}</style>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default UploadPhoto;
