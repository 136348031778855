import React, { useRef } from "react";
import Footer from "./Footer";
import { ChevronRight, CircleCheck, XCircle } from "lucide-react";

const Guideline = (props) => {
  const { positiveSampleImage, negativeSampleImage, myKadSide, onNext } = props;

  const inputRef = useRef(null);

  const onChange = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);

    reader.onload = async () => {
      onNext(reader.result);
    };
  };

  return (
    <div className="p-4 rounded-t-xl bg-white flex flex-col h-full min-h-screen">
      <h2 className="text-xl font-bold mb-2 text-center">
        Please take a clear photo of your
        <br /> <span className="text-blue-600">MyKad ({myKadSide === "front" ? "front" : "back"})</span>
      </h2>
      <p className="text-gray-500 mb-6 text-left max-w-prose">
        Position your MyKad correctly, ensure good lighting, and avoid glare or reflections. See our example below for
        guidance. You can place the MyKad on your palm to angle it if required.
      </p>

      <div className="flex flex-col items-center relative">
        <div className="mb-2 flex items-center justify-center">
          <img
            src={positiveSampleImage}
            className="h-auto w-80 sm:h-auto sm:w-80 lg:h-80 lg:w-auto max-h-[200px] object-contain rounded-lg shadow-md shadow-black"
          />
        </div>
        <CircleCheck
          className="text-green-600 font-bold text-center absolute top-1/2 transform -translate-y-1/2 opacity-50"
          width={80}
          height={80}
        />
      </div>
      <p className="text-center text-gray-500 text-sm font-bold mt-1">Good: No glare & reflection. Correct angle.</p>

      <div className="mt-4" />

      <div className="flex flex-col items-center relative">
        <div className="mb-2 flex items-center justify-center">
          <img
            src={negativeSampleImage}
            className="h-auto w-80 sm:h-auto sm:w-80 lg:h-80 lg:w-auto max-h-[200px] object-contain rounded-lg shadow-md shadow-black"
          />
        </div>
        <XCircle
          className="text-red-600 font-bold text-center absolute top-1/2 transform -translate-y-1/2 opacity-50"
          width={80}
          height={80}
        />
      </div>
      <p className="text-center text-gray-500 text-sm font-bold mt-1">Bad: Glare & reflection.</p>

      <div className="mb-8" />

      <div className="flex flex-1" />

      <button
        className="w-2/3 bg-blue-600 text-white py-3 px-4 rounded-full flex items-center justify-center space-x-2 mx-auto transition active:scale-95 duration-150 ease-in-out"
        onClick={() => inputRef.current.click()}
      >
        <span>Continue</span>
        <ChevronRight className="w-5 h-5" />
      </button>

      <input ref={inputRef} accept="image/*" type="file" capture="environment" hidden onChange={onChange} />

      <Footer />
    </div>
  );
};

export default Guideline;
