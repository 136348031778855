import React, { useState, useEffect } from "react";
import GuidelinePassport from "./components/GuidelinePassport";
import GuidelineLiveness from "./components/GuidelineLiveness";
import CheckQuality from "./components/CheckQuality";
import Verifying from "./components/Verifying";
import Success from "./components/Success";
import TakePhotoError from "./components/TakePhotoError";
import Liveness from "./components/Liveness";
import { useLocation } from "react-router-dom";
import InvalidToken from "./components/InvalidToken";
import UploadPhoto from "./components/UploadPhoto";
import PassportReject from "./components/PassportReject";
import { usePassportService } from "./usePassportService";
import LivenessReject from "./components/LivenessReject";

const Passport = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const { getValidationRecordFromToken, upload, verify } = usePassportService();

  const [step, setStep] = useState("passport-guideline");
  // const [step, setStep] = useState("hologram-detection-guideline");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [id, setId] = useState(null);

  const [progressStates, setProgressStates] = useState({
    passport: "Not Started",
    liveness: "Not Started",
  });

  const [redirectURL, setRedirectURL] = useState(null);

  const [mrzTextArray, setMrzTextArray] = useState([]);
  const [mrzImageBlob, setMrzImageBlob] = useState(null);
  const [passportOriginalImageBlob, setPassportOriginalImageBlob] = useState(null);
  const [passportImageBlob, setPassportImageBlob] = useState(null);
  const [takePhotoErrorMessage, setTakePhotoErrorMessage] = useState("");

  const [userUploadImageBuffer, setUserUploadImageBuffer] = useState(null);

  const [livenessError, setLivenessError] = useState({
    errorMessage: "",
  });

  const [hasPassportCheckCompleted, setHasPassportCheckCompleted] = useState(false);

  useEffect(() => {
    if (token) {
      getValidationRecordFromToken(token).then((res) => {
        if (res.status === "success") {
          setId(res.data._default + "." + res.data._id);
          setProgressStates({
            passport: res.data.StepOneResult ? res.data.StepOneResult : "Not Started",
            liveness: res.data.StepTwoResult ? res.data.StepTwoResult : "Not Started",
          });
          setRedirectURL(res.data.RedirectURL);
        } else {
          setStep("invalid-token");
        }
      });
    }
  }, [token]);

  useEffect(() => {
    if (progressStates.passport === "Pending") {
      setStep("verifying");
    } else if (progressStates.passport === "Not Started") {
      setStep("passport-guideline");
    } else if (progressStates.passport === "Fail") {
      setStep("passport-rejected");
    } else if (progressStates.liveness === "Not Started" || progressStates.liveness === "Fail") {
      setStep("liveness-guideline");
    } else {
      setStep("success");
    }
  }, [progressStates]);

  const renderSteps = () => {
    switch (step) {
      case "passport-guideline":
        return (
          <GuidelinePassport
            onNext={(imageBuffer) => {
              setUserUploadImageBuffer(imageBuffer);
              setStep("passport-upload");
            }}
          />
        );
      case "passport-upload":
        return (
          <UploadPhoto
            imageBuffer={userUploadImageBuffer}
            onSuccess={({ croppedImageBlob, originalImageBlob, mrzImageBlob, mrzTextArray }) => {
              setPassportImageBlob(croppedImageBlob);
              setPassportOriginalImageBlob(originalImageBlob);
              setMrzImageBlob(mrzImageBlob);
              setMrzTextArray(mrzTextArray);
              setStep("passport-check-quality");
            }}
            onError={(error) => {
              setTakePhotoErrorMessage(error);
              setStep("passport-upload-error");
            }}
          />
        );
      case "passport-upload-error":
        return (
          <TakePhotoError
            errorMessage={takePhotoErrorMessage}
            imageBuffer={userUploadImageBuffer}
            onRetry={() => {
              setTakePhotoErrorMessage("");
              setStep("passport-guideline");
            }}
          />
        );
      case "passport-check-quality":
        return (
          <CheckQuality
            isSubmitting={isSubmitting}
            imageBlob={passportImageBlob}
            onRetake={(imageBuffer) => {
              setUserUploadImageBuffer(imageBuffer);
              setStep("passport-upload");
            }}
            onConfirm={() => {
              setIsSubmitting(true);
              setHasPassportCheckCompleted(false);

              const formData = new FormData();
              formData.append("id", id);
              formData.append("croppedImage", passportImageBlob);
              formData.append("originalImage", passportOriginalImageBlob);
              formData.append("mrzImage", mrzImageBlob);
              formData.append("mrzText", JSON.stringify(mrzTextArray));

              upload(formData)
                .then((res) => {
                  if (res.status === "success") {
                    verify({ id }).then((res) => {
                      setHasPassportCheckCompleted(true);
                    });
                    setStep("verifying");
                  }

                  setIsSubmitting(false);
                })
                .catch(() => {
                  setIsSubmitting(false);
                });
            }}
          />
        );
      case "passport-rejected":
        return (
          <PassportReject
            id={id}
            onRetry={() => {
              setStep("passport-guideline");
            }}
          />
        );
      case "verifying":
        return (
          <Verifying
            id={id}
            progressStates={progressStates}
            hasPassportCheckCompleted={hasPassportCheckCompleted}
            onPass={(checkResult) => {
              setProgressStates((prev) => ({ ...prev, passport: "Pass" }));
              setStep("liveness-guideline");
            }}
            onFail={(checkResult) => {
              if (checkResult.validationCount >= 3) {
                return setStep("exceed-max-retry");
              }
              setProgressStates((prev) => ({ ...prev, passport: "Fail" }));
              setStep("passport-rejected");
            }}
          />
        );
      case "liveness-guideline":
        return <GuidelineLiveness onNext={() => setStep("liveness-upload")} />;
      case "liveness-upload":
        return (
          <Liveness
            id={id}
            onSuccess={() => setStep("success")}
            onFail={(error) => {
              setLivenessError({
                errorMessage: error.failMessage,
              });
              setStep("liveness-rejected");
            }}
          />
        );
      case "liveness-rejected":
        return (
          <LivenessReject
            errorMessage={livenessError.errorMessage}
            onRetry={() => {
              setLivenessError({ errorMessage: "" });
              setStep("liveness-upload");
            }}
          />
        );
      case "success":
        return (
          <Success
            onDone={() => {
              setPassportImageBlob(null);
              setPassportOriginalImageBlob(null);
              setUserUploadImageBuffer(null);
              setMrzImageBlob(null);
              setMrzTextArray([]);
              // setProgressStates({
              //   passport: "Not Started",
              //   liveness: "Not Started",
              // });

              if (redirectURL) {
                window.location.replace(redirectURL);

                // Prevent navigating back to previous pages
                window.history.pushState(null, "", window.location.href);
                window.addEventListener("popstate", function (event) {
                  window.history.pushState(null, "", window.location.href);
                });
              }
            }}
          />
        );
      case "invalid-token":
        return <InvalidToken />;
      default:
        return <div>Loading...</div>;
    }
  };

  return (
    <div className="h-auto w-full bg-gray-50 flex justify-center items-center">
      <div className="h-full w-full max-w-md bg-black relative md:rounded-xl md:shadow-lg">
        {!token ? <InvalidToken /> : renderSteps()}
      </div>
    </div>
  );
};

export default Passport;
