import React from "react";
import Footer from "./Footer";
import { CircleX } from "lucide-react";

const InvalidToken = () => {
  return (
    <div className="bg-white h-full flex flex-col justify-start p-4 min-h-screen">
      <div className="bg-gray-100 p-8 rounded-2xl mt-[calc(100vh/16)] mb-8">
        <div className="flex flex-col">
          <p className="text-2xl font-medium">Invalid or expired session</p>
          <p className="text-gray-500 text-md mt-4">Your session has expired or invalid. Please try again.</p>
        </div>
        <div className="flex flex-col items-center mt-8">
          <CircleX height="72" width="72" color="red" />
        </div>
      </div>

      <div className="flex flex-1" />

      <Footer />
    </div>
  );
};

export default InvalidToken;
