import { useCallback, useContext } from "react";
import { AuthContext } from "../../App";
import { SERVER_HOST } from "../../constants/config";

const BaseAPIUrl = SERVER_HOST;

const usePassportService = () => {
  const { authState } = useContext(AuthContext);

  const getValidationRecordFromToken = useCallback(
    async (token) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
        },
      };

      return fetch(`${BaseAPIUrl}/passport-verification/passport/session?token=${token}`, config).then((response) =>
        response.json()
      );
    },
    [authState]
  );

  const upload = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
        },
        body: params,
      };

      return fetch(BaseAPIUrl + "/v2/passport-verification/passport/upload", config).then((response) =>
        response.json()
      );
    },
    [authState]
  );

  const verify = useCallback(
    async (params) => {
      const config = {
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(params),
      };

      return fetch(BaseAPIUrl + "/v2/passport-verification/passport/verify", config).then((response) =>
        response.json()
      );
    },
    [authState]
  );

  const checkResult = useCallback(
    async (params) => {
      const config = {
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(params),
      };

      return fetch(BaseAPIUrl + "/passport-verification/passport/check-result", config).then((response) =>
        response.json()
      );
    },
    [authState]
  );

  const getPassportRejectReason = useCallback(
    async (params) => {
      const config = {
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(params),
      };

      return fetch(BaseAPIUrl + "/passport-verification/passport/reject-reason", config).then((response) =>
        response.json()
      );
    },
    [authState]
  );

  return {
    // start,
    getValidationRecordFromToken,
    upload,
    verify,
    checkResult,
    getPassportRejectReason,
  };
};

export { usePassportService };
