import React, { useRef } from "react";
import Footer from "./Footer";
import passportGood from "../../../assets/images/passportguideline.png";
import { ChevronRight } from "lucide-react";

const GuidelinePassport = (props) => {
  const { onNext } = props;

  const inputRef = useRef(null);

  const onChange = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);

    reader.onload = async () => {
      onNext(reader.result);
    };
  };

  return (
    <div className="p-4 rounded-t-xl bg-white flex flex-col h-full min-h-screen">
      <h2 className="text-xl font-bold mb-2">
        Take a photo
        <br />
        of your <span className="text-blue-600">Passport</span>
      </h2>
      <p className="text-gray-500 mb-6 md:mb-8 text-left max-w-prose">
        Please make sure the MRZ is clear, readable and at the <strong>bottom</strong> of the image. We show a sample
        photo below to help you get it right.
      </p>

      <div className="flex flex-col items-center mb-8">
        <div className="mb-2 flex items-center justify-center">
          <img src={passportGood} className="w-80 h-auto rounded-lg shadow-md shadow-black" />
        </div>
      </div>

      <div className="flex flex-1" />

      <button
        className="w-2/3 bg-blue-600 text-white py-3 px-4 rounded-full flex items-center justify-center space-x-2 mx-auto transition active:scale-95 duration-150 ease-in-out"
        onClick={() => inputRef.current.click()}
      >
        <span>Continue</span>
        <ChevronRight className="w-5 h-5" />
      </button>

      <input ref={inputRef} accept="image/*" type="file" capture="environment" hidden onChange={onChange} />

      <Footer />
    </div>
  );
};

export default GuidelinePassport;
