import React, { useState, useContext, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import DocLivenessImage from "../../../assets/images/DocLivenessImage.jpeg";
import { Button, Card, Heading } from "@radix-ui/themes";
import ImagePlaceholder from "./ImagePlaceholder";

const SelfieWithMyKad = (props) => {
  const { onSubmit, isSubmitting } = props;

  const documentLivenessInputRef = useRef(null);

  const [documentLivenessBase64, setDocumentLivenessBase64] = useState(null);

  const [documentLivenessResult, setDocumentLivenessResult] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [failVerification, setFailVerification] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [possibleFailReasonList, setPossibleFailReasonList] = useState([]);

  const [isMobile, setIsMobile] = useState(false);
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const handleFlipImage = () => {
    if (documentLivenessBase64) {
      const img = new Image();
      img.src = `data:image/jpeg;base64,${documentLivenessBase64}`;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set canvas dimensions
        canvas.width = img.width;
        canvas.height = img.height;

        // Apply horizontal flip transformation
        ctx.translate(canvas.width, 0);
        ctx.scale(-1, 1);

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0);

        // Extract the flipped image as a Base64 string
        const flippedBase64 = canvas.toDataURL("image/jpeg").split(",")[1];

        // Update the state with the flipped image
        setDocumentLivenessBase64(flippedBase64);
      };
    }
  };

  useEffect(() => {
    const mobilePatterns = [/android/i, /iPad|iPhone|iPod/i, /windows phone/i, /blackberry/i, /opera mini/i, /mobile/i];
    const isMobileDevice = mobilePatterns.some((pattern) => pattern.test(userAgent));
    setIsMobile(isMobileDevice);
  }, []);

  return (
    <div className="min-h-screen p-4 flex items-center justify-center">
      {failVerification ? (
        <Box
          p={2}
          sx={{
            maxWidth: {
              xs: "100%",
              sm: "80%",
              md: "70%",
              lg: "60%",
              xl: "50%",
            },
            margin: "0 auto",
          }}
        >
          <Box textAlign={"center"}>
            <Box my={2}>
              <CancelIcon htmlColor="#F1612E" style={{ fontSize: 72 }} />
              <Typography fontWeight={"bold"} fontSize={24}>
                {errorMessage}
              </Typography>
            </Box>
            <Typography variant="body1">Please refer to the details below for the solution.</Typography>
          </Box>
          <Box px={2} mt={4}>
            <Box textAlign={"center"} bgcolor={"#CFC7CA"} p={2}>
              <Box>
                <Typography fontWeight="bold" my={2}>
                  Selfie with MyKad
                </Typography>

                <Box border={"2px solid #000"}>
                  <img
                    src={"data:image/jpeg;base64," + documentLivenessBase64}
                    alt="MyKad Front"
                    width={"100%"}
                    height={"auto"}
                  />
                </Box>
                <Typography my={2} mb={1}>
                  {documentLivenessResult?.["CustomerName"]}
                </Typography>
                <Typography>{documentLivenessResult?.["NRICFront"]}</Typography>
              </Box>
              <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
            </Box>
            <Grid container>
              <Grid item xs={6} border={"1px solid #000"} p={1}>
                <Typography fontWeight="bold">Possible Reasons </Typography>
              </Grid>
              <Grid item xs={6} border={"1px solid #000"} borderLeft={"none"} p={1}>
                <Typography fontWeight="bold">Solution </Typography>
              </Grid>
              {possibleFailReasonList.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={6} border={"1px solid #000"} p={1}>
                    {item.reason}
                  </Grid>
                  <Grid item xs={6} border={"1px solid #000"} borderLeft={"none"} p={1}>
                    {item.solution}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>

          <Box textAlign={"center"} mt={4}>
            <Button
              variant="contained"
              onClick={() => {
                setDocumentLivenessResult(null);
                setFailVerification(false);
                setErrorMessage(null);
              }}
              sx={{
                backgroundColor: "#B41E8E",
                minWidth: "120px",
                borderColor: "#000",
              }}
            >
              RETRY
            </Button>
          </Box>
        </Box>
      ) : (
        <Card className="w-full max-w-md">
          <Box className="text-center mb-4">
            <Heading size="5">Please take a picture with your IC just like the sample below</Heading>
          </Box>

          <ImagePlaceholder
            text={"Selfie with IC"}
            base64Image={documentLivenessBase64}
            inputRef={documentLivenessInputRef}
            onChange={async (e) => {
              const file = e.target.files[0];
              if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                  const base64String = reader.result.split(",")[1];
                  setDocumentLivenessBase64(base64String);
                };
                reader.readAsDataURL(file);
              }
            }}
          >
            <img
              src={DocLivenessImage}
              alt="Sample of how to take a good picture of your IC"
              width={"auto"}
              height={"140px"}
              style={{
                maxHeight: "180px",
              }}
            />
          </ImagePlaceholder>

          <div className="flex items-center justify-center mt-4">
            {documentLivenessBase64 ? (
              <p className="text-center text-sm text-gray-500">
                If your image is mirrored, click{" "}
                <span className="text-blue-500 cursor-pointer underline" onClick={handleFlipImage}>
                  here
                </span>{" "}
                to flip the image.
              </p>
            ) : (
              <Button
                onClick={() => {
                  documentLivenessInputRef.current.click();
                }}
              >
                Take Photo
              </Button>
            )}
          </div>

          <div className="flex items-center justify-center mt-8">
            <Button
              className="w-full group"
              onClick={() => onSubmit(documentLivenessBase64)}
              loading={isSubmitting}
              disabled={!documentLivenessBase64 || submitting}
            >
              Submit
            </Button>
          </div>
        </Card>
      )}
    </div>
  );
};

export default SelfieWithMyKad;
