import React, { useState, useEffect } from "react";
import Guideline from "./components/Guideline";
import GuidelineLiveness from "./components/GuidelineLiveness";
import CheckQuality from "./components/CheckQuality";
import Verifying from "./components/Verifying";
import Success from "./components/Success";
import mykadFrontGood from "../../assets/images/guideline-ic-front-good.jpg";
import mykadFrontBad from "../../assets/images/guideline-ic-front-bad.jpg";
import mykadBackGood from "../../assets/images/guideline-ic-back-good.jpg";
import mykadBackBad from "../../assets/images/guideline-ic-back-bad.jpg";
import TakePhotoError from "./components/TakePhotoError";
import Liveness from "./components/Liveness";
import { useLocation } from "react-router-dom";
import InvalidToken from "./components/InvalidToken";
import { useMyKadService } from "./useMyKadService";
import UploadPhoto from "./components/UploadPhoto";
import MyKadReject from "./components/MyKadReject";
import MultipleReject from "./components/MultipleReject";
import GuidelineSelfieWithMyKad from "./components/GuidelineSelfieWithMyKad";
import CheckQualitySelfieWithMyKad from "./components/CheckQualitySelfieWithMyKad";
import GuidelineHologramDetection from "./components/GuidelineHologramDetection";
import CheckQualityHologramDetection from "./components/CheckQualityHologramDetection";
import ExceedMaxRetry from "./components/ExceedMaxRetry";
import LivenessReject from "./components/LivenessReject";
import EditNameModal from "./components/EditNameModal";
import { set } from "react-hook-form";
import EditIDNumberModal from "./components/EditIDNumberModal";

const MyKad = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const {
    getValidationRecordFromToken,
    uploadFront,
    verifyFront,
    uploadBack,
    verifyBack,
    verifySelfieWithMyKad,
    verifyHologram,
  } = useMyKadService();

  const [step, setStep] = useState("mykad-front-guideline");
  // const [step, setStep] = useState("hologram-detection-guideline");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [id, setId] = useState(null);
  const [hasMyKadFrontCheckCompleted, setHasMyKadFrontCheckCompleted] = useState(false);
  const [hasMyKadBackCheckCompleted, setHasMyKadBackCheckCompleted] = useState(false);
  const [hasSelfieWithMyKadCheckCompleted, setHasSelfieWithMyKadCheckCompleted] = useState(false);
  const [hasHologramDetectionCheckCompleted, setHasHologramDetectionCheckCompleted] = useState(false);

  const [progressStates, setProgressStates] = useState({
    myKadFront: "Not Started", // Not Started, Fail, Pass
    myKadBack: "Not Started",
    selfieWithMyKad: "Not Started",
    hologramDetection: "Not Started",
    liveness: "Not Started",
  });

  const [redirectURL, setRedirectURL] = useState(null);
  const [workflowConfig, setWorkflowConfig] = useState({});
  const [otherConfig, setOtherConfig] = useState({});
  const [userUploadImageBuffer, setUserUploadImageBuffer] = useState(null);
  const [myKadFrontOriginalImageBlob, setMyKadFrontOriginalImageBlob] = useState(null);
  const [myKadFrontImageBlob, setMyKadFrontImageBlob] = useState(null);
  const [myKadBackOriginalImageBlob, setMyKadBackOriginalImageBlob] = useState(null);
  const [myKadBackImageBlob, setMyKadBackImageBlob] = useState(null);
  const [useOCForData, setUseOCRForData] = useState(false);
  const [ocrName, setOcrName] = useState("");
  const [ocrIDNumber, setOcrIDNumber] = useState("");
  const [editNameModal, setEditNameModal] = useState(false);
  const [editIDNumberModal, setEditIDNumberModal] = useState(false);

  const [takePhotoErrorMessage, setTakePhotoErrorMessage] = useState("");
  const [livenessError, setLivenessError] = useState({
    errorMessage: "",
  });

  useEffect(() => {
    if (token) {
      getValidationRecordFromToken(token).then((res) => {
        if (res.status === "success") {
          if (res.data.validationCount >= 3) {
            return setStep("exceed-max-retry");
          }

          setId(res.data.id);
          setProgressStates({
            myKadFront: res.data.myKadFrontResult ? res.data.myKadFrontResult : "Not Started",
            myKadBack: res.data.myKadBackResult ? res.data.myKadBackResult : "Not Started",
            selfieWithMyKad: res.data.selfieWithMyKadResult ? res.data.selfieWithMyKadResult : "Not Started",
            hologramDetection: res.data.hologramDetectionResult ? res.data.hologramDetectionResult : "Not Started",
            liveness: res.data.stepTwoResult ? res.data.stepTwoResult : "Not Started",
          });
          setRedirectURL(res.data.redirectURL);
          setWorkflowConfig(res.data.workflowConfig);
          setOtherConfig(res.data.otherConfig);
          setUseOCRForData(res.data.useOCRForData);
        } else {
          setStep("invalid-token");
        }
      });
    }
  }, [token]);

  useEffect(() => {
    let totalFail = 0;

    if (progressStates.myKadFront === "Fail") {
      totalFail++;
    }
    if (progressStates.myKadBack === "Fail") {
      totalFail++;
    }
    if (progressStates.selfieWithMyKad === "Fail") {
      totalFail++;
    }
    if (progressStates.hologramDetection === "Fail") {
      totalFail++;
    }

    if (totalFail > 1) {
      return setStep("multiple-reject");
    }

    if (progressStates.myKadFront === "Pending" || progressStates.myKadBack === "Pending") {
      setStep("verifying");
    } else if (progressStates.myKadFront === "Not Started") {
      setStep("mykad-front-guideline");
    } else if (progressStates.myKadFront === "Fail") {
      setStep("mykad-front-rejected");
    } else if (progressStates.myKadBack === "Not Started") {
      setStep("mykad-back-guideline");
    } else if (progressStates.myKadBack === "Fail") {
      setStep("mykad-back-rejected");
    } else if (progressStates.selfieWithMyKad === "Not Started" && workflowConfig["Selfie With MyKad"]) {
      setStep("selfie-with-mykad-guideline");
    } else if (progressStates.selfieWithMyKad === "Fail") {
      setStep("selfie-with-mykad-rejected");
    } else if (progressStates.hologramDetection === "Not Started" && workflowConfig["Hologram Detection"]) {
      setStep("hologram-detection-guideline");
    } else if (progressStates.hologramDetection === "Fail") {
      setStep("hologram-detection-rejected");
    } else if (progressStates.liveness === "Not Started" || progressStates.liveness === "Fail") {
      setStep("guideline-liveness");
    } else {
      setStep("success");
    }
  }, [progressStates, workflowConfig]);

  const onNext = (currentStep) => {
    // From current step, determine which step to go next, based on workflow config and also progressStates.

    if (currentStep === "myKadFront") {
      if (progressStates.myKadBack === "Fail" || progressStates.myKadBack === "Not Started") {
        setStep("mykad-back-guideline");
      } else if (
        workflowConfig["Selfie With MyKad"] &&
        (progressStates.selfieWithMyKad === "Fail" || progressStates.selfieWithMyKad === "Not Started")
      ) {
        setStep("selfie-with-mykad-guideline");
      } else if (
        workflowConfig["Hologram Detection"] &&
        (progressStates.hologramDetection === "Fail" || progressStates.hologramDetection === "Not Started")
      ) {
        setStep("hologram-detection-guideline");
      } else {
        setStep("verifying");
      }
    } else if (currentStep === "myKadBack") {
      if (
        workflowConfig["Selfie With MyKad"] &&
        (progressStates.selfieWithMyKad === "Fail" || progressStates.selfieWithMyKad === "Not Started")
      ) {
        setStep("selfie-with-mykad-guideline");
      } else if (
        workflowConfig["Hologram Detection"] &&
        (progressStates.hologramDetection === "Fail" || progressStates.hologramDetection === "Not Started")
      ) {
        setStep("hologram-detection-guideline");
      } else {
        setStep("verifying");
      }
    } else if (currentStep === "selfieWithMyKad") {
      if (
        workflowConfig["Hologram Detection"] &&
        (progressStates.hologramDetection === "Fail" || progressStates.hologramDetection === "Not Started")
      ) {
        setStep("hologram-detection-guideline");
      } else {
        setStep("verifying");
      }
    } else if (currentStep === "hologramDetection") {
      setStep("verifying");
    } else if (currentStep === "verifying") {
      setStep("guideline-liveness");
    }
  };

  const renderSteps = () => {
    switch (step) {
      case "mykad-front-guideline":
        return (
          <Guideline
            myKadSide="front"
            positiveSampleImage={mykadFrontGood}
            negativeSampleImage={mykadFrontBad}
            onNext={(imageBuffer) => {
              setUserUploadImageBuffer(imageBuffer);
              setStep("mykad-front-upload");
            }}
          />
        );
      case "mykad-front-upload":
        return (
          <UploadPhoto
            useOCRForData={useOCForData}
            imageBuffer={userUploadImageBuffer}
            onSuccess={({ croppedImageBlob, originalImageBlob, ocrName, ocrIDNumber }) => {
              setMyKadFrontImageBlob(croppedImageBlob);
              setMyKadFrontOriginalImageBlob(originalImageBlob);

              if (useOCForData) {
                setOcrName(ocrName);
                setOcrIDNumber(ocrIDNumber);
              }
              setStep("mykad-front-check-quality");
            }}
            onError={(error) => {
              setTakePhotoErrorMessage(error);
              setStep("mykad-front-upload-error");
            }}
          />
        );
      case "mykad-front-upload-error":
        return (
          <TakePhotoError
            errorMessage={takePhotoErrorMessage}
            imageBuffer={userUploadImageBuffer}
            onRetry={() => {
              setTakePhotoErrorMessage("");
              setStep("mykad-front-guideline");
            }}
          />
        );
      case "mykad-front-check-quality":
        return (
          <CheckQuality
            useOCRForData={useOCForData}
            ocrName={ocrName}
            ocrIDNumber={ocrIDNumber}
            isSubmitting={isSubmitting}
            myKadSide="front"
            imageBlob={myKadFrontImageBlob}
            onRetake={(imageBuffer) => {
              setUserUploadImageBuffer(imageBuffer);
              setStep("mykad-front-upload");
            }}
            onConfirm={() => {
              setIsSubmitting(true);
              setHasMyKadFrontCheckCompleted(false);

              const formData = new FormData();
              formData.append("id", id);
              formData.append("originalImage", myKadFrontOriginalImageBlob);
              formData.append("croppedImage", myKadFrontImageBlob);

              if (useOCForData) {
                formData.append("ocrName", ocrName);
                formData.append("ocrIDNumber", ocrIDNumber);
              }

              uploadFront(formData)
                .then((res) => {
                  if (res.status === "success") {
                    // Submitting for verification, and proceed to mykad back immediately
                    verifyFront({ id }).then((res) => {
                      setHasMyKadFrontCheckCompleted(true);
                    });
                    onNext("myKadFront");
                  }
                  setIsSubmitting(false);
                })
                .catch(() => {
                  setIsSubmitting(false);
                });
            }}
            onEditName={() => {
              setEditNameModal(true);
            }}
            onEditIDNumber={() => {
              setEditIDNumberModal(true);
            }}
          />
        );
      case "mykad-front-rejected":
        return <MyKadReject id={id} myKadSide="front" onRetry={() => setStep("mykad-front-guideline")} />;
      case "mykad-back-guideline":
        return (
          <Guideline
            myKadSide="back"
            positiveSampleImage={mykadBackGood}
            negativeSampleImage={mykadBackBad}
            onNext={(imageBuffer) => {
              setUserUploadImageBuffer(imageBuffer);
              setStep("mykad-back-upload");
            }}
          />
        );
      case "mykad-back-upload":
        return (
          <UploadPhoto
            imageBuffer={userUploadImageBuffer}
            onSuccess={({ croppedImageBlob, originalImageBlob }) => {
              setMyKadBackImageBlob(croppedImageBlob);
              setMyKadBackOriginalImageBlob(originalImageBlob);
              setStep("mykad-back-check-quality");
            }}
            onError={(error) => {
              setTakePhotoErrorMessage(error);
              setStep("mykad-back-upload-error");
            }}
          />
        );
      case "mykad-back-upload-error":
        return (
          <TakePhotoError
            errorMessage={takePhotoErrorMessage}
            imageBuffer={userUploadImageBuffer}
            onRetry={() => {
              setTakePhotoErrorMessage("");
              setStep("mykad-back-guideline");
            }}
          />
        );
      case "mykad-back-check-quality":
        return (
          <CheckQuality
            isSubmitting={isSubmitting}
            myKadSide="back"
            imageBlob={myKadBackImageBlob}
            onRetake={(imageBuffer) => {
              setUserUploadImageBuffer(imageBuffer);
              setStep("mykad-back-upload");
            }}
            onConfirm={() => {
              setIsSubmitting(true);
              setHasMyKadBackCheckCompleted(false);

              const formData = new FormData();
              formData.append("id", id);
              formData.append("originalImage", myKadBackOriginalImageBlob);
              formData.append("croppedImage", myKadBackImageBlob);

              uploadBack(formData)
                .then((res) => {
                  if (res.status === "success") {
                    // Submitting for verification, and proceed to verifiying screen immediately
                    verifyBack({ id }).then((res) => {
                      setHasMyKadBackCheckCompleted(true);
                    });

                    onNext("myKadBack");
                  }
                  setIsSubmitting(false);
                })
                .catch(() => {
                  setIsSubmitting(false);
                });
            }}
          />
        );
      case "mykad-back-rejected":
        return <MyKadReject id={id} myKadSide="back" onRetry={() => setStep("mykad-back-guideline")} />;
      case "selfie-with-mykad-guideline":
        return (
          <GuidelineSelfieWithMyKad
            onNext={(imageBuffer) => {
              setUserUploadImageBuffer(imageBuffer);
              setStep("selfie-with-mykad-check-quality");
            }}
          />
        );
      case "selfie-with-mykad-check-quality":
        return (
          <CheckQualitySelfieWithMyKad
            isSubmitting={isSubmitting}
            imageBuffer={userUploadImageBuffer}
            onRetake={(imageBuffer) => {
              setUserUploadImageBuffer(imageBuffer);
              setStep("selfie-with-mykad-check-quality");
            }}
            onConfirm={(imageBlob) => {
              setIsSubmitting(true);
              setHasSelfieWithMyKadCheckCompleted(false);
              const formData = new FormData();
              formData.append("id", id);
              formData.append("file", imageBlob);

              verifySelfieWithMyKad(formData)
                .then((res) => {
                  setHasSelfieWithMyKadCheckCompleted(true);
                  onNext("selfieWithMyKad");
                  setIsSubmitting(false);
                })
                .catch(() => {
                  setIsSubmitting(false);
                });
            }}
          />
        );
      case "selfie-with-mykad-rejected":
        return (
          <MyKadReject id={id} myKadSide="selfieWithMyKad" onRetry={() => setStep("selfie-with-mykad-guideline")} />
        );
      case "hologram-detection-guideline":
        return (
          <GuidelineHologramDetection
            onNext={(imageBuffer) => {
              setUserUploadImageBuffer(imageBuffer);
              setStep("hologram-detection-check-quality");
            }}
          />
        );
      case "hologram-detection-check-quality":
        return (
          <CheckQualityHologramDetection
            isSubmitting={isSubmitting}
            imageBuffer={userUploadImageBuffer}
            onRetake={(imageBuffer) => {
              setUserUploadImageBuffer(imageBuffer);
              setStep("hologram-detection-check-quality");
            }}
            onConfirm={(imageBlob) => {
              setIsSubmitting(true);
              setHasHologramDetectionCheckCompleted(false);
              const formData = new FormData();
              formData.append("id", id);
              formData.append("file", imageBlob);

              verifyHologram(formData)
                .then((res) => {
                  setHasHologramDetectionCheckCompleted(true);
                  onNext("hologramDetection");
                  setIsSubmitting(false);
                })
                .catch(() => {
                  setIsSubmitting(false);
                });
            }}
          />
        );
      case "hologram-detection-rejected":
        return (
          <MyKadReject id={id} myKadSide="hologramDetection" onRetry={() => setStep("hologram-detection-guideline")} />
        );
      case "verifying":
        return (
          <Verifying
            id={id}
            progressStates={progressStates}
            workflowConfig={workflowConfig}
            hasMyKadFrontCheckCompleted={hasMyKadFrontCheckCompleted}
            hasMyKadBackCheckCompleted={hasMyKadBackCheckCompleted}
            hasSelfieWithMyKadCheckCompleted={hasSelfieWithMyKadCheckCompleted}
            hasHologramDetectionCheckCompleted={hasHologramDetectionCheckCompleted}
            onPass={(checkResult) => {
              setProgressStates((prev) => ({
                ...prev,
                myKadFront: checkResult.front.status,
                myKadBack: checkResult.back.status,
                selfieWithMyKad: checkResult.selfie ? checkResult.selfie : prev.selfieWithMyKad,
                hologramDetection: checkResult.hologram ? checkResult.hologram : prev.hologramDetection,
              }));
              onNext("verifying");
            }}
            onFail={(checkResult) => {
              if (checkResult.validationCount >= 3) {
                return setStep("exceed-max-retry");
              }

              setTimeout(() => {
                setProgressStates((prev) => ({
                  ...prev,
                  myKadFront: checkResult.front.status,
                  myKadBack: checkResult.back.status,
                  selfieWithMyKad: checkResult.selfie ? checkResult.selfie : prev.selfieWithMyKad,
                  hologramDetection: checkResult.hologram ? checkResult.hologram : prev.hologramDetection,
                }));
              }, 2000);
            }}
          />
        );
      case "multiple-reject":
        return (
          <MultipleReject
            progressStates={progressStates}
            onRetry={() => {
              if (progressStates.myKadFront === "Fail") {
                setStep("mykad-front-guideline");
              } else if (progressStates.myKadBack === "Fail") {
                setStep("mykad-back-guideline");
              } else if (progressStates.selfieWithMyKad === "Fail") {
                setStep("selfie-with-mykad-guideline");
              } else if (progressStates.hologramDetection === "Fail") {
                setStep("hologram-detection-guideline");
              }
            }}
          />
        );
      case "guideline-liveness":
        return <GuidelineLiveness onNext={() => setStep("take-liveness")} />;
      case "take-liveness":
        return (
          <Liveness
            id={id}
            onSuccess={() => setStep("success")}
            onFail={(error) => {
              setLivenessError({
                errorMessage: error.failMessage,
              });
              setStep("liveness-rejected");
            }}
          />
        );
      case "liveness-rejected":
        return (
          <LivenessReject
            errorMessage={livenessError.errorMessage}
            onRetry={() => {
              setLivenessError({ errorMessage: "" });
              setStep("take-liveness");
            }}
          />
        );
      case "success":
        return (
          <Success
            onDone={() => {
              setMyKadFrontImageBlob(null);
              setMyKadBackImageBlob(null);
              setMyKadFrontOriginalImageBlob(null);
              setMyKadBackOriginalImageBlob(null);
              setUserUploadImageBuffer(null);
              setOcrName("");
              setOcrIDNumber("");
              // setProgressStates({
              //   myKadFront: "Not Started",
              //   myKadBack: "Not Started",
              //   liveness: "Not Started",
              // });

              if (redirectURL) {
                window.location.replace(redirectURL);

                // Prevent navigating back to previous pages
                window.history.pushState(null, "", window.location.href);
                window.addEventListener("popstate", function (event) {
                  window.history.pushState(null, "", window.location.href);
                });
              }
            }}
          />
        );
      case "exceed-max-retry":
        return <ExceedMaxRetry />;
      case "invalid-token":
        return <InvalidToken />;
      default:
        // Return loading screen
        return <div>Loading...</div>;
    }
  };

  return (
    <div className="min-h-screen w-full bg-gray-50 flex justify-center items-center">
      <div className="min-h-screen w-full max-w-md bg-black relative md:rounded-xl md:shadow-lg">
        {!token ? <InvalidToken /> : renderSteps()}

        <EditNameModal
          isOpen={editNameModal}
          initialName={ocrName}
          onClose={() => setEditNameModal(false)}
          onSave={(newName) => {
            setOcrName(newName);
            setEditNameModal(false);
          }}
        />

        <EditIDNumberModal
          isOpen={editIDNumberModal}
          initialIDNumber={ocrIDNumber}
          onClose={() => setEditIDNumberModal(false)}
          onSave={(newIDNumber) => {
            setOcrIDNumber(newIDNumber);
            setEditIDNumberModal(false);
          }}
        />
      </div>
    </div>
  );
};

export default MyKad;
