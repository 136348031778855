import { Button, Text } from "@radix-ui/themes";

const ImagePlaceholder = ({ text, base64Image, inputRef, onChange, children }) => (
  <>
    {base64Image ? (
      <div className="flex flex-col items-center">
        <img
          src={`data:image/jpeg;base64,${base64Image}`}
          alt="Preview"
          width={"100%"}
          height={"auto"}
          style={{ maxHeight: "200px", objectFit: "contain" }}
        />
        <Button style={{ marginTop: "8px" }} variant="outline" onClick={() => inputRef.current.click()}>
          Retake
        </Button>
      </div>
    ) : (
      <div
        className="flex items-center justify-center"
        style={{
          border: "2px dashed var(--gray-6)",
          borderRadius: "8px",
          padding: "16px",
          minHeight: "180px",
          cursor: "pointer",
          aspectRatio: 1.585,
        }}
        onClick={() => inputRef.current.click()}
      >
        <div className="flex flex-col items-center">
          {children ? children : <Text size="5">📸</Text>}
          <Text align="center" mt={"1"}>
            {text}
          </Text>
        </div>
      </div>
    )}
    <input ref={inputRef} accept="image/*" type="file" capture="environment" hidden onChange={onChange} />
  </>
);

export default ImagePlaceholder;
