import { AlertTriangle } from "lucide-react";

const ExceedMaxRetry = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-3 text-center">
      <AlertTriangle className="w-16 h-16 text-red-600 mb-2" />
      <h5 className="text-2xl font-bold mb-2">Maximum Verification Attempts Exceeded</h5>
      <p className="text-gray-600 mb-4">
        You have reached the maximum number of verification attempts. Please contact support for further assistance.
      </p>
    </div>
  );
};

export default ExceedMaxRetry;
