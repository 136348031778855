import React, { useState, useEffect, useCallback } from "react";
import { Button, Text } from "@radix-ui/themes";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { useFaceLivenessService } from "../../ekyc/services/useFaceLivenessService";

const Liveness = (props) => {
  const { id, onSuccess, onFail } = props;

  const { createStreamSession, getLivenessSessionResult, compareMyKadProfileWithLivePerson2 } =
    useFaceLivenessService();

  const [isStarted, setIsStarted] = useState(false);
  const [faceLivenessSessionId, setFaceLivenessSessionId] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isStarted) {
      onFaceLivenessStart();
    }

    return () => {
      console.log("cleanup");
      // setFaceLivenessSessionId(null);
      // setIsStarted(false);
    };
  }, [isStarted]);

  const onFaceLivenessStart = async () => {
    setLoading(true);

    try {
      const res = await createStreamSession();
      if (res.status === "success") {
        setFaceLivenessSessionId(res.data.SessionId);
        setIsStarted(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const CustomError = useCallback(() => {
    return (
      <div className="flex items-center justify-center bg-red-100 text-red-600 border rounded-lg p-4 gap-4">
        <Text>{error?.error.message}</Text>
        <Button onClick={reset}>Try Again</Button>
      </div>
    );
  }, [error]);

  const reset = () => {
    setFaceLivenessSessionId(null);
    onFaceLivenessStart();
  };

  const onAnalysisComplete = async (sessionId) => {
    try {
      const res = await getLivenessSessionResult({
        id: id,
        sessionId: sessionId,
      });
      if (res.status === "success") {
        onCompareFaces();
      } else {
        onFail({
          failMessage: "Failed to verify face liveness. Please try again.",
        });
      }
    } catch (error) {
      onFail({
        failMessage: "Failed to verify face liveness. Please try again.",
      });
    }
  };

  const onCompareFaces = async () => {
    const res = await compareMyKadProfileWithLivePerson2({
      id: id,
    });
    if (res.status === "success") {
      onSuccess({
        profilePhotoBase64: res.data.profilePhotoBase64,
        liveFacePhotoBase64: res.data.liveFacePhotoBase64,
      });
    } else {
      onFail({
        failMessage: res.message,
        profilePhotoBase64: res.data.profilePhotoBase64,
        liveFacePhotoBase64: res.data.liveFacePhotoBase64,
      });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      {loading ? (
        <Text>Loading. Please wait.</Text>
      ) : (
        <FaceLivenessDetector
          disableStartScreen={true}
          sessionId={faceLivenessSessionId}
          region="ap-northeast-1"
          onAnalysisComplete={() => onAnalysisComplete(faceLivenessSessionId)}
          onError={(error) => setError(error)}
          onUserCancel={() => {
            setFaceLivenessSessionId();
            setIsStarted(false);
          }}
          components={{
            ErrorView: CustomError,
          }}
        />
      )}
    </div>
  );
};

export default Liveness;
