import React, { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { AspectRatio } from "react-aspect-ratio";
import Webcam from "react-webcam";
import base64ToFile from "../../../utils/base64ToFile";
import { Camera, Circle, Image } from "lucide-react";
import { EKYCContext } from "../EKYC3";
const ChooseImageSourceDialog = ({ open, handleClose, onClick }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Choose Image Source</DialogTitle>
      <DialogContent>
        <div className="text-gray-600">How would you like to capture the image?</div>

        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div
            className="flex flex-col items-center justify-center"
            style={{
              border: "2px dashed var(--gray-8)",
              borderRadius: "8px",
              padding: "2rem",
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() => onClick("camera")}
          >
            <Camera size={48} color="var(--gray-8)" />
            <div className="mt-2">Camera</div>
          </div>
          <div
            className="flex flex-col items-center justify-center"
            style={{
              border: "2px dashed var(--gray-8)",
              borderRadius: "8px",
              padding: "2rem",
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() => onClick("upload")}
          >
            <Image size={48} color="var(--gray-8)" />

            <div className="mt-2">Upload</div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const videoConstraints = {
  facingMode: "environment",
  width: {
    min: 1280,
    ideal: 4096,
  },
  height: {
    min: 720,
    ideal: 2160,
  },
  aspectRatio: 85 / 54,
};

const WebcamDialog = ({ open, handleClose, onCapture }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <Webcam
        forceScreenshotSourceSize
        audio={false}
        screenshotQuality={1}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      >
        {({ getScreenshot }) => (
          <div className="flex flex-col items-center justify-center bg-black p-4">
            <div
              className="text-white cursor-pointer"
              onClick={() => {
                const base64 = getScreenshot();
                onCapture(base64ToFile(base64));
              }}
            >
              <Circle size={72} color="white" fill="white" />
            </div>
          </div>
        )}
      </Webcam>
    </Dialog>
  );
};

const MyKadInputPlaceholder = ({ inputRef, base64Image, onChange, children }) => {
  const { myKadOtherConfig } = useContext(EKYCContext);

  const [isMobile, setIsMobile] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showWebcamDialog, setShowWebcamDialog] = useState(false);
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  useEffect(() => {
    const mobilePatterns = [/android/i, /iPad|iPhone|iPod/i, /windows phone/i, /blackberry/i, /opera mini/i, /mobile/i];
    const isMobileDevice = mobilePatterns.some((pattern) => pattern.test(userAgent));
    setIsMobile(isMobileDevice);
  }, []);

  return (
    <Box>
      <ButtonBase
        style={{
          border: "1px solid #B41E8E",
        }}
        sx={{ width: "100%" }}
        onClick={() => {
          if (isMobile) {
            inputRef.current.click();
            return;
          }

          // If configuration does not enable the user to take a photo of the user with MyKad using webcam, directly open the gallery.
          if (
            !myKadOtherConfig?.["EnableWebcamForTakingPhoto"] ||
            !myKadOtherConfig?.["EnableWebcamForTakingPhoto"]["enabled"]
          ) {
            inputRef.current.click();
            return;
          }
          // When click, check whether the webcam is available. If available, show a dialog to choose between camera and gallery.
          // If not available, directly open the gallery.
          if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            setDialogOpen(true);
          } else {
            inputRef.current.click();
          }
        }}
      >
        <AspectRatio
          ratio={"3/4"}
          style={{
            width: "100%",
            minHeight: "180px",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {base64Image ? (
            <img src={"data:image/jpeg;base64," + base64Image} alt="MyKad Front" width={"100%"} height={"auto"} />
          ) : (
            <>
              <PhotoCameraIcon fontSize={"large"} htmlColor="#B41E8E" />
              {children}
            </>
          )}

          <input
            ref={inputRef}
            accept="image/*"
            id="mykad-front"
            type="file"
            capture="environment"
            hidden
            onChange={(e) => {
              setDialogOpen(false);
              onChange(e);
            }}
          />
        </AspectRatio>
      </ButtonBase>

      {base64Image && (
        <>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                inputRef.current.click();
              }}
            >
              Retake
            </Button>
          </Box>
        </>
      )}

      <ChooseImageSourceDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        onClick={(source) => {
          if (source === "camera") {
            // Here we can use the camera
            setShowWebcamDialog(true);
          } else {
            inputRef.current.click();
          }
        }}
      />

      <WebcamDialog
        open={showWebcamDialog}
        handleClose={() => setShowWebcamDialog(false)}
        onCapture={(file) => {
          setShowWebcamDialog(false);
          setDialogOpen(false);
          onChange({ target: { files: [file] }, sourceOrigin: "webcam" });
        }}
      />
    </Box>
  );
};

export default MyKadInputPlaceholder;
