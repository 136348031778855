import React, { useContext, useState, useRef, useEffect } from "react";
import { Heading, Button, Box, Card } from "@radix-ui/themes";
import ImageProcessDialog from "./ImageProcessDialog";
import { EKYCMyKadContext } from "../MyKad";
import ImagePlaceholder from "./ImagePlaceholder";

const Upload = ({ isSubmitting, onSubmit }) => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const { id } = useContext(EKYCMyKadContext);

  const myKadFrontInputRef = useRef(null);
  const myKadBackInputRef = useRef(null);

  const [myKadFrontBase64, setMyKadFrontBase64] = useState(null);
  const [myKadBackBase64, setMyKadBackBase64] = useState(null);

  const [isMobile, setIsMobile] = useState(false);

  const [isProcessingImage, setIsProcessingImage] = useState(false);
  const [processingFile, setProcessingFile] = useState(null);
  const [myKadSide, setMyKadSide] = useState(null);
  const [sourceOrigin, setSourceOrigin] = useState(null);

  useEffect(() => {
    const mobilePatterns = [/android/i, /iPad|iPhone|iPod/i, /windows phone/i, /blackberry/i, /opera mini/i, /mobile/i];
    const isMobileDevice = mobilePatterns.some((pattern) => pattern.test(userAgent));
    setIsMobile(isMobileDevice);
  }, []);

  return (
    <div className="min-h-screen p-4 flex items-center justify-center">
      <Card className="w-full max-w-sm">
        <Box className="text-center mb-4">
          <Heading size="5">Take a photo of your MyKad</Heading>
        </Box>

        <div className="flex flex-col space-y-4 p-4">
          <ImagePlaceholder
            text="Front IC"
            base64Image={myKadFrontBase64}
            inputRef={myKadFrontInputRef}
            onChange={async (e) => {
              const file = e.target.files[0];
              setProcessingFile(file);
              !isMobile ? setSourceOrigin() : setSourceOrigin("scanbot");
              setMyKadSide("front");
              setIsProcessingImage(true);

              // reset the inputRef
              myKadFrontInputRef.current.value = null;
            }}
          />

          <ImagePlaceholder
            text="Back IC"
            base64Image={myKadBackBase64}
            inputRef={myKadBackInputRef}
            onChange={async (e) => {
              const file = e.target.files[0];
              setProcessingFile(file);
              !isMobile ? setSourceOrigin() : setSourceOrigin("scanbot");
              setMyKadSide("back");
              setIsProcessingImage(true);

              // reset the inputRef
              myKadBackInputRef.current.value = null;
            }}
          />
        </div>

        <Button
          className="w-full group"
          style={{ marginTop: "8px" }}
          onClick={onSubmit}
          disabled={!myKadFrontBase64 || !myKadBackBase64 || isSubmitting}
          loading={isSubmitting}
        >
          Submit
        </Button>

        <ImageProcessDialog
          id={id}
          uploadFile={processingFile}
          myKadSide={myKadSide}
          sourceOrigin={sourceOrigin}
          visible={isProcessingImage}
          onSuccess={(base64) => {
            if (myKadSide === "back") {
              setMyKadBackBase64(base64);
            } else {
              setMyKadFrontBase64(base64);
            }
            setIsProcessingImage(false);
          }}
          onError={(message) => {
            setIsProcessingImage(false);
            alert(message);
          }}
          onClose={() => {
            setIsProcessingImage(false);
          }}
        />
      </Card>
    </div>
  );
};

export default Upload;
