import React from "react";
import Footer from "./Footer";
import { ChevronRight } from "lucide-react";

const GuidelineLiveness = (props) => {
  const { onNext } = props;

  return (
    <div className="p-4 rounded-t-xl bg-white flex flex-col h-full min-h-screen">
      <h2 className="text-xl font-bold mb-4 text-center">Prepare for Liveness Check</h2>
      <p className="text-gray-500 mb-4 md:mb-8 text-left max-w-prose">
        We'll now verify that you are physically present. This quick security step helps protect your identity.
      </p>

      <ul className="space-y-2 mb-16">
        <li className="flex items-start space-x-2">
          <ChevronRight className="w-6 h-6 text-blue-600" />
          <span>Find a well-lit area</span>
        </li>
        <li className="flex items-start space-x-2">
          <ChevronRight className="w-6 h-6 text-blue-600" />
          <span>Position your face clearly in the frame</span>
        </li>
        <li className="flex items-start space-x-2">
          <ChevronRight className="w-6 h-6 text-blue-600" />
          <span>Make sure you are alone in the camera view</span>
        </li>
      </ul>

      <div className="flex flex-1" />

      <button
        className="w-2/3 bg-blue-600 text-white py-3 px-4 rounded-full flex items-center justify-center space-x-2 mx-auto transition active:scale-95 duration-150 ease-in-out"
        onClick={onNext}
      >
        <span>Continue</span>
        <ChevronRight className="w-5 h-5" />
      </button>

      <Footer />
    </div>
  );
};

export default GuidelineLiveness;
