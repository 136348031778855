import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import EKYC3 from "../modules/ekyc/EKYC3";
import CertificatePage from "../modules/ekyc/components/CertificatePage";
import MyKad from "../modules/mykad/MyKad";
import MyKadV2 from "../modules/mykad_v2/MyKad";
import Passport from "../modules/passport/Passport";
import PassportV2 from "../modules/passport_v2/Passport";
import VerificationSuccess from "../modules/VerificationSuccess";

const ThemeRoutes = () => {
  return useRoutes([
    {
      path: "/",
      element: <Navigate to="/ekyc/mykad" replace />,
    },
    {
      path: "/ekycv3",
      element: <EKYC3 />,
    },
    {
      path: "/verification-certificate/:clientID/:refID",
      element: <CertificatePage />,
    },
    {
      path: "/ekyc/mykad",
      element: <MyKad />,
    },
    {
      path: "/ekyc/mykad/v2",
      element: <MyKadV2 />,
    },
    {
      path: "/ekyc/passport",
      element: <Passport />,
    },
    {
      path: "/ekyc/passport/v2",
      element: <PassportV2 />,
    },
    {
      path: "/ekyc/verification-success",
      element: <VerificationSuccess />,
    },
  ]);
};

export default ThemeRoutes;
