import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import GoodGuideline from "../../../assets/images/icguideline-good.jpg";
import BadGuideline from "../../../assets/images/icguideline-badCropped.jpg";

const HintDialog = ({ open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{"Picture Taking Guidelines"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          To ensure a smooth process, please follow these guidelines when taking pictures of your IC:
          <ul className="list-disc list-inside mt-2 space-y-1">
            <li>Ensure good lighting conditions</li>
            <li>Place the IC on a flat, contrasting surface</li>
            <li>Capture the entire IC in the frame</li>
            <li>Avoid glare or reflections on the IC</li>
            <li>Make sure the image is clear and in focus</li>
            <li>Ensure the image's four corners align with the edges of the box</li>
          </ul>
        </DialogContentText>

        <div className="mt-4">
          <h3 className="font-bold text-lg">Correct Example:</h3>
          <img
            src={GoodGuideline}
            alt="Good IC Cropped Example"
            style={{ width: "100%", height: "auto", objectFit: "contain" }} // Ensures it fits the dialog
          />

          <h3 className="font-bold text-lg mt-4">Incorrect Example:</h3>
          <img
            src={BadGuideline}
            alt="Bad IC Cropped Example"
            style={{ width: "100%", height: "auto", objectFit: "contain" }} // Ensures it fits the dialog
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HintDialog;
