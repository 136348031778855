import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import { CircleX } from "lucide-react";
import { usePassportService } from "../usePassportService";

const PassportReject = ({ id, onRetry }) => {
  const { getPassportRejectReason } = usePassportService();
  const [errorMessage, setErrorMessage] = useState("");
  const [failReasonAndSolutionList, setFailReasonAndSolutionList] = useState([]);

  useEffect(() => {
    if (id) {
      getPassportRejectReason({
        id,
      }).then((res) => {
        if (res.status === "success") {
          setErrorMessage(res.data.errorMessage);
          setFailReasonAndSolutionList(res.data.failReasonAndSolutionList);
        } else {
          setErrorMessage("There was an error while verifying your MyKad.");
        }
      });
    }
  }, [id]);

  return (
    <div className="bg-white h-full flex flex-col justify-start p-4 min-h-screen">
      <div className="bg-gray-100 p-8 rounded-2xl mt-[calc(100vh/16)] mb-8">
        <div className="flex flex-col items-center mb-8">
          <CircleX height="72" width="72" color="red" />
        </div>
        <div className="flex flex-col">
          <p className="text-2xl font-medium">
            Oops! We couldn't verify your <span className="text-blue-700">Passport</span>.
          </p>
          <p className="text-gray-500 text-md mt-4">{errorMessage}</p>

          {
            <ul className="text-gray-500 text-md mt-4">
              {failReasonAndSolutionList.map((item, index) => (
                <li key={index} className="mt-2">
                  <p className="text-gray-800 text-md font-medium">{item.Reason}</p>
                  <p className="text-gray-500 text-md">{item.Resolution}</p>
                </li>
              ))}
            </ul>
          }
        </div>
      </div>

      <div className="flex flex-1" />

      <button
        className="w-2/3 bg-blue-600 text-white py-3 px-4 rounded-full flex items-center justify-center space-x-2 mx-auto transition active:scale-95 duration-150 ease-in-out"
        onClick={onRetry}
      >
        <span>Try Again</span>
      </button>
      <Footer />
    </div>
  );
};

export default PassportReject;
