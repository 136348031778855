import React from "react";

const VerificationSuccess = () => {
  return (
    <div className="flex items-center justify-center min-h-screen p-12 bg-gray-100">
      <div className="w-full max-w-lg text-center animate-fadeIn">
        <h1 className="text-3xl font-bold text-green-600 mb-6">Verification Success! 🎉</h1>
        <div className="bg-white rounded-xl p-8 shadow-lg mb-6">
          <div className="bg-green-100 rounded-full w-20 h-20 mx-auto mb-5 flex items-center justify-center animate-pulse">
            <span className="text-4xl font-bold animate-checkmarkDraw">✓</span>
          </div>
          <p className="text-lg text-green-600 leading-relaxed mb-6">Your identity has been successfully verified.</p>
          <div className="text-left animate-slideIn">
            <h3 className="text-lg font-semibold text-blue-600 mb-3">Next Steps:</h3>
            <ol className="list-decimal list-inside text-gray-600 space-y-2">
              <li className="animate-slideIn delay-100">Return to your computer</li>
              <li className="animate-slideIn delay-200">The main application page will automatically update</li>
              <li className="animate-slideIn delay-300">Continue with your application process</li>
            </ol>
          </div>
        </div>
        <div className="border-t border-gray-200 pt-4 animate-fadeIn delay-400">
          <p className="text-sm text-gray-500">You can safely close this window</p>
          <p className="text-xs text-gray-400">
            For security purposes, this session will automatically expire in 5 minutes
          </p>
        </div>
      </div>
    </div>
  );
};

export default VerificationSuccess;
