import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import { CircleX } from "lucide-react";

const TakePhotoError = ({ errorMessage, imageBuffer, onRetry }) => {
  const [imageBlob, setImageBlob] = useState(null);

  useEffect(() => {
    if (imageBuffer) {
      setImageBlob(new Blob([imageBuffer], { type: "image/jpeg" }));
    }
  }, [imageBuffer]);

  return (
    <div className="bg-white h-full flex flex-col justify-start p-4 min-h-screen">
      <div className="bg-gray-100 p-8 rounded-2xl mt-[calc(100vh/16)] mb-8">
        <div className="flex flex-col items-center mb-8">
          <CircleX height="72" width="72" color="red" />
        </div>
        <div className="flex flex-col">
          <p className="text-2xl font-medium">Oops! Something went wrong.</p>
          <p className="text-gray-500 text-lg mt-4">{errorMessage}</p>
        </div>

        <div className="flex flex-col items-center mt-8">
          {imageBlob && <img src={URL.createObjectURL(imageBlob)} alt="MyKad" className="w-60" />}
        </div>
      </div>

      <div className="flex flex-1" />

      <button
        className="w-2/3 bg-blue-600 text-white py-3 px-4 rounded-full flex items-center justify-center space-x-2 mx-auto transition active:scale-95 duration-150 ease-in-out"
        onClick={onRetry}
      >
        <span>Try Again</span>
      </button>
      <Footer />
    </div>
  );
};

export default TakePhotoError;
