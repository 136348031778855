import React, { useState, useEffect } from "react";
import ScanbotSDK from "scanbot-web-sdk";

const UploadPhoto = (props) => {
  const { imageBuffer, onSuccess, onError, useOCRForData } = props;

  const [hasMounted, setHasMounted] = useState(false);
  const [sdk, setSdk] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    setHasMounted(true);
    if (imageBuffer) {
      // Create a blob URL from imageBuffer
      const blob = new Blob([imageBuffer], { type: "image/jpeg" });
      setImageUrl(URL.createObjectURL(blob));
    }

    return () => {
      setHasMounted(false);
      // Clean up the blob URL when the component unmounts
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, []);

  useEffect(() => {
    if (hasMounted) {
      const initializeSdk = async () => {
        const sdk = await ScanbotSDK.initialize({ licenseKey: "" });

        setSdk(sdk);
      };

      initializeSdk();

      return () => {
        if (sdk) {
          setSdk(null);
        }
      };
    }
  }, [hasMounted]);

  useEffect(() => {
    if (sdk && imageBuffer) {
      detectDocument(imageBuffer);
    }
  }, [sdk, imageBuffer]);

  const detectDocument = async (imageBuffer) => {
    const result = await sdk.detectDocument(imageBuffer, 75, 60);

    if (result.success !== true) {
      return onError("Unable to detect document. Please try again.");
    }
    if (result.detectionStatus === "NotAcquired" || result.detectionStatus === "Error_NothingDetected") {
      return onError("No document detected. Please try again.");
    }
    if (result.detectionStatus === "OK_BadAngles") {
      return onError("Bad angles. Please try again.");
    }
    if (result.detectionStatus === "Error_Brightness") {
      return onError("Bad brightness. Please try again.");
    }
    if (result.detectionStatus === "Error_Noise") {
      return onError("Too much noise. Please try again.");
    }
    if (result.detectionStatus === "OK_OffCenter") {
      return onError("Document is off center. Please try again.");
    }
    if ("OK_SmallSize" === result.detectionStatus) {
      return onError("Image is too small. Please move closer to the document.");
    }

    let cropped = await sdk.cropAndRotateImageCcw(imageBuffer, result.polygon, 0);

    const ocr = await sdk.createOcrEngine();

    const [_0, _90, _180, _270] = await Promise.all([
      sdk.rotateImageCcw(cropped, 0), // rotate 0 time counter clockwise
      sdk.rotateImageCcw(cropped, 1), // rotate 1 time counter clockwise
      sdk.rotateImageCcw(cropped, 2), // rotate 2 time counter clockwise
      sdk.rotateImageCcw(cropped, 3), // rotate 3 time counter clockwise
    ]);

    // Perform OCR on all 4 rotations
    let rotatedImages = [_0, _90, _180, _270];

    const isCorrectOrientation = async (image) => {
      const imageProcessor = await sdk.createImageProcessor(image);
      await imageProcessor.crop([
        { x: 0.65, y: 0.75 },
        { x: 1, y: 0.75 },
        { x: 1, y: 1 },
        { x: 0.65, y: 1 },
      ]);
      const processedImage = await imageProcessor.processedImage();

      let ocrResult = await ocr.performOcr(processedImage);

      imageProcessor.release();

      if (!ocrResult.ocrElement || !ocrResult.ocrElement.text) {
        return false;
      }

      const texts = ocrResult.ocrElement.text.split("\n");

      if (
        texts.some(
          (text) =>
            text === "WARGANEGARA" ||
            text === "LELAKI" ||
            text === "PEREMPUAN" ||
            text === "ISLAM" ||
            text === "chip" ||
            text === "80K" ||
            text === "64K"
        )
      ) {
        return true;
      }

      return false;
    };

    for (let i = 0; i < rotatedImages.length; i++) {
      if (await isCorrectOrientation(rotatedImages[i])) {
        cropped = rotatedImages[i];
        break;
      }
    }

    let name = null;
    let idNumber = null;
    // Perform OCR on the cropped image if useOCRForData is true
    if (useOCRForData) {
      // Crop the name region, and perform OCR.
      let nameRegion = await sdk.cropAndRotateImageCcw(
        cropped,
        [
          { x: 0, y: 0.59 },
          { x: 0.65, y: 0.59 },
          { x: 0.65, y: 0.75 },
          { x: 0, y: 0.75 },
        ],
        0
      );

      const imageProcessor = await sdk.createImageProcessor(nameRegion);
      await imageProcessor.resize(300);
      nameRegion = await imageProcessor.processedImage();
      imageProcessor.release();

      // Perform OCR on the name region
      const nameOcr = await ocr.performOcr(nameRegion);

      if (nameOcr.ocrElement && nameOcr.ocrElement.text) {
        name = nameOcr.ocrElement.text;
      }

      // Crop the ID number region, and perform OCR.
      let idNumberRegion = await sdk.cropAndRotateImageCcw(
        cropped,
        [
          { x: 0, y: 0.185 },
          { x: 0.41, y: 0.185 },
          { x: 0.41, y: 0.33 },
          { x: 0, y: 0.33 },
        ],
        0
      );

      const imageProcessor2 = await sdk.createImageProcessor(idNumberRegion);
      await imageProcessor2.resize(300);
      idNumberRegion = await imageProcessor2.processedImage();
      imageProcessor2.release();

      // Download idNumberRegion
      // const blob = new Blob([idNumberRegion], { type: "image/jpeg" });
      // const url = URL.createObjectURL(blob);
      // const a = document.createElement("a");
      // a.href = url;
      // a.download = "idNumberRegion.jpg";
      // a.click();

      // Perform OCR on the id number region
      const idNumberOcr = await ocr.performOcr(idNumberRegion);

      if (idNumberOcr.ocrElement && idNumberOcr.ocrElement.text) {
        idNumber = idNumberOcr.ocrElement.text;
        idNumber = idNumber.replace(/[^0-9]/g, "");
      }

      // if (idNumberOcr.blocks && idNumberOcr.blocks.length > 0) {
      //   if (idNumberOcr.blocks[0].lines && idNumberOcr.blocks[0].lines.length > 0) {
      //     idNumber = idNumberOcr.blocks[0].lines[0].ocrElement.text;
      //     idNumber = idNumber.replace(/[^0-9]/g, "");
      //   }
      // }
    }

    ocr.release();

    const croppedFile = new Blob([cropped], { type: "image/jpeg" });
    const originalFile = new Blob([imageBuffer], { type: "image/jpeg" });
    onSuccess({ croppedImageBlob: croppedFile, originalImageBlob: originalFile, ocrName: name, ocrIDNumber: idNumber });
  };

  return sdk ? (
    <div className="fixed inset-0 flex items-center justify-center bg-black">
      {imageUrl && (
        <img src={imageUrl} alt="Uploaded" className="mx-auto my-auto max-w-full max-h-full object-contain filter" />
      )}
      {/* Scanning line overlay */}
      <div className="absolute inset-0">
        <div className="scanner" />
      </div>
      {/* Centered text overlay */}
      <div className="absolute inset-0 flex items-center justify-center">
        <p className="text-white font-semibold text-center">Please wait while we are processing your photo...</p>
      </div>
      <style jsx>{`
        .scanner {
          position: absolute;
          width: 100%;
          height: 4px; /* Line thickness */
          background: rgba(0, 255, 0, 0.8);
          animation: scan 2s linear infinite;
        }
        @keyframes scan {
          0% {
            top: 0;
          }
          100% {
            top: calc(100% - 4px);
          }
        }
      `}</style>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default UploadPhoto;
