import { useEffect, useState } from "react";

export default function EditNameModal({ isOpen, onClose, onSave, initialName }) {
  const [name, setName] = useState(initialName);

  useEffect(() => {
    setName(initialName);
  }, [initialName]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-semibold mb-4">Edit Name</h2>
        <input
          type="text"
          value={name}
          onChange={(e) => {
            // to upper case
            let newName = e.target.value.toUpperCase();
            setName(newName);
          }}
          className="w-full border p-2 rounded-md mb-4"
        />
        <div className="flex justify-end space-x-2">
          <button onClick={onClose} className="px-4 py-2 bg-gray-300 rounded-md">
            Cancel
          </button>
          <button onClick={() => onSave(name)} className="px-4 py-2 bg-blue-500 text-white rounded-md">
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
